/* eslint-disable */
/* eslint-disable import/no-unresolved */
import { FC, MouseEvent, useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { setCall } from "src/redux/slices/calls-ui";
import { toggleArrow } from "src/redux/slices/ui";

import { Spinner } from "@doar/components";

import { UserContext } from "src/config/userContext";
import Label from "../label";
import Item from "./item";
import { StyledChatWrap } from "./style";

interface IProps {
  spinner: boolean;
}

const SidebarMessages: FC<IProps> = ({ spinner }) => {
  const dispatch = useAppDispatch();
  const { callsArr } = useAppSelector((state) => state.callsUI);
  const { user } = useContext(UserContext);

  const [activeId, setActiveId] = useState("");
  const [llamadasArr, setLlamadasArr] = useState<any>([]);
  const handleClick = (e: MouseEvent<HTMLDivElement>, call: any) => {
    const target = e.target as HTMLElement;
    let targetId = activeId;
    if (!target.classList.contains("media")) {
      const id = target.closest(".media")?.id;
      targetId = id || activeId;
    } else {
      targetId = target.id || activeId;
    }
    setActiveId(targetId);

    dispatch(toggleArrow({ isOpen: true }));

    dispatch(setCall(call));
  };

  function timeConverter(ISO_timestamp: any) {
    return new Date(ISO_timestamp).toLocaleTimeString();
  }

  function dayConverter(ISO_timestamp: any) {
    return new Date(ISO_timestamp).toLocaleDateString();
  }

  useEffect(() => {

    setLlamadasArr(callsArr.payload);
    if (callsArr?.payload?.length > 0) {
      setActiveId(callsArr.payload[0].id);
      dispatch(setCall(callsArr.payload[0]));
    }
  }, [callsArr]);

  return (
    <StyledChatWrap>
      {!spinner && (
        <div style={{ height: "65vh" }}>
          <div
            style={{
              position: "absolute",
              left: "calc(50% - 24px)",
              top: "calc(50% - 34px)",
            }}
          >
            <Spinner size="xl" />
          </div>
        </div>
      )}
      {spinner && (
        <div>
          <Label mb="10px" pl="10px">
            Llamadas
          </Label>
          <div>
            {(llamadasArr?.length === 0 || !llamadasArr) && (
              <p
                style={{
                  position: "absolute",
                  width: "90%",
                  textAlign: "center",

                  top: "calc(50% - 34px)",
                }}
              >
                No hay llamadas para mostrar
              </p>
            )}
            {llamadasArr?.map((el: any) => {
              return (
                // <div key={el.id} style={{display:"flex", width:"100%"}}>
                <Item
                  key={el.id}
                  id={el.id}
                  activeId={activeId}
                  onClick={(e: any) => handleClick(e, el)}
                  status="online"
                  // tag={el[1].tags}
                  userName={el.source}
                  userPhone={el.source}
                  userLogo={el.source}
                  name={el.origin_bot_id}
                  lastMsgDay={dayConverter(el.start)}
                  lastMsgTime={timeConverter(el.start)}
                />
                // </div>
              );
            })}
          </div>
        </div>
      )}
    </StyledChatWrap>
  );
};

export default SidebarMessages;
