/* eslint-disable */
/* eslint-disable import/no-unresolved */
import "./style.css";

import { Badge } from "antd";
import moment from "moment";
import { FC, useContext, useEffect, useState } from "react";
import { BarChart2, Edit, Phone } from "react-feather";
import { useNavigate } from "react-router-dom";
import { CustomerContext, UserContext } from "src/config/userContext";

/* eslint-disable import/no-unresolved */
import { Button, Card, CardBody, CardImage, Col, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, Row, Spinner } from "@doar/components";
import fakeCustomer from "./fakeCustomer.json";

import Breadcrumb from "../../components/breadcrumb";
import { StyledWelcomeArea, StyledWelcomeLeft } from "../dashboard-four/welcome-area/style";
import API from "@doar/shared/methods/api";

const LocalesContainer: FC = () => {
  const { customer, customerUpdate } = useContext(CustomerContext);
  const { user } = useContext(UserContext);
  const [showPhone, setShowPhone] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [venueList, setVenueList] = useState<any[]>([]);
  const navigate = useNavigate();
  const restImage = "https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/restaurantes-cerca-madrid-casa-elena-toledo-1548885651.jpg";

  function getImage() {
    // switch (vertical) {
    //   case "RESTAURANT":
    return "https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/restaurantes-cerca-madrid-casa-elena-toledo-1548885651.jpg";
    //   case "HOTEL":
    //     return "https://pesweb.azureedge.net/spimg/hotelbannerimages/pestana-sao-paulo-hotel-banner-01.jpg";
    //   case "CLUB":
    //     return "https://golsmedia.com/wp-content/uploads/2021/05/todo-necesitas-saber-crear-club-deportivo-e1622449688440.jpg";
    //   case "CLINICA":
    //     return "https://clinicasauramed.com/wp-content/uploads/2021/12/clinica1.jpg";
    //   case "TAXI":
    //     return "https://talcualdigital.com/wp-content/uploads/2018/08/Taxi_1.jpg";
    //   default:
    //     return "";
    // }
  }
  function getColor(vertical: string) {
    switch (vertical) {
      case "RESTAURANT":
        return "red";
      case "HOTEL":
        return "blue";
      case "CLUB":
        return "yellow";
      case "CLINICA":
        return "green";
      case "TAXI":
        return "black";
      default:
        return "";
    }
  }

  // const handleNewBot = () => {
  //   navigate(`/newBotEdit/`);
  //   // navigate(`/bot/`);
  // };
  const handlePhoneModal = (bot: any) => {
    setShowPhone((prev) => !prev);
  };

  const handleEdit = (bot?: any) => {
    // if (bot) {
    //   // navigate(`/bot/${bot.bot.id as string}`);
    //   if (bot.bot.config.bot_type === "RESTAURANT") {
    //     navigate(`/restaurant-bot/${bot.bot.id as string}`);
    //   } else if (bot.bot.config.bot_type === "FLEXBOT") {
    //     navigate(`/newBotEdit/${bot.bot.id as string}`);
    //   }
    // }
  };
  const handleClose = () => {
    setShowPhone((prev) => !prev);
  };

  async function getVenues(customerID: string) {
    const response = await API.get("/venues?customer_id=" + customerID);
    if(response){

      console.log("DATAAAAA", response.data.data);
      setVenueList(response.data.data);
    }

    // if (Array.isArray(response.data) && response.data.length > 0) {
    //   if (response.data[0].bots) {
    //     setVenueList(response.data[0].bots);
    //     console.log(response.data[0].bots);
    //   } else {
    //     console.log("No 'bots' property found in the first element of response.data");
    //   }
    // } else {
    //   console.log("response.data is empty or not structured as expected");
    // }
  }

  useEffect(() => {
    if (customer && customer.id !== "defaultCustomer") {
      getVenues(customer.id);
    }
  }, [customer]);

  return (
    <div id="page-wrapper" className="container">
      <StyledWelcomeArea>
        <StyledWelcomeLeft>
          <Breadcrumb prev={[{ text: "General", link: "/" }]} title="Locales" wcText="Locales" />
        </StyledWelcomeLeft>
      </StyledWelcomeArea>
      {spinner && (
        <div className="spinnerContainer">
          <Spinner className="spinner" size="xl" />
        </div>
      )}
      {!spinner && (
        <Row mb="30px">
          {venueList?.map((unit: any) => (
            <Col key={unit.id} mt={20} sm={4}>
              <Card className="botCard">
                <Badge.Ribbon text="RESTAURANT" color={getColor(unit.type)}>
                  <div className="imageContainer">
                    <div className="iconContainer">
                      <div role="button" tabIndex={0} onClick={() => handlePhoneModal(unit)} className="phoneIconContainer">
                        <Phone className="phoneIcon" />
                        <span className="tooltiptext">Diversion instructions</span>
                      </div>
                      <div
                        role="button"
                        tabIndex={0}
                        // onClick={() => navigate("/")}
                        className="statsIconContainer"
                      >
                        <BarChart2 className="statsIcon" />
                        <span className="tooltiptext">Show stats</span>
                      </div>
                      <div
                        role="button"
                        tabIndex={0}
                        // onClick={() => handleEdit(unit)}
                        className="editIconContainer"
                      >
                        <Edit className="editIcon" />
                        <span className="tooltiptext">Edit Bot</span>
                      </div>
                    </div>
                    <div>
                      <CardImage className="botImage" isTop src={getImage()} alt="placehold image" />
                    </div>
                  </div>
                  <CardBody className="botBody">
                    {/* <p className="botType">
                      <small className={bot.assignments.length > 0 ? "online" : "offline"}>{bot.assignments.length > 0 ? "Online" : "Offline"}</small>
                    </p> */}
                    <div role="button" tabIndex={0} onClick={() => handleEdit(unit)}>
                      <h5 className="botTitle">{unit.name}</h5>
                    </div>
                    <h6 className="h6style">
                      Last call was&nbsp;
                      {moment(new Date()).fromNow()}{" "}
                    </h6>
                  </CardBody>
                </Badge.Ribbon>
              </Card>
            </Col>
          ))}
        </Row>
      )}

      <Modal show={showPhone} centered onClose={handleClose}>
        <ModalHeader>
          <ModalTitle>Phone Settings</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <p>Aqui van todas las instrucciones para hacer los desvios y demas</p>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleClose} color="secondary">
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default LocalesContainer;
