/* eslint-disable */
import { FC, MouseEvent, useContext, useEffect, useState } from "react";
import { Card, CardBody, CardFooter, SectionTitle, ApexCharts, ApexLineChart, Row, Col, Progress, Select } from "@doar/components";
import { ticketChart } from "@doar/shared/data/dashboard-four";
import { StyledHeader, StyledHeaderLeft, StyledList, StyledListItem, StyledListText, StyledBullet, StyledChart, StyledNumb, StyleTitle, StyledText, StyledRate } from "./style";
import { CustomerContext } from "src/config/userContext";
import API from "@doar/shared/methods/api";

interface IProps {
  change?: any;
}

const TicketStatus: FC<IProps> = (props) => {
  const { series, options } = ticketChart;
  const { customer } = useContext(CustomerContext);
  const chartToggle = (e: MouseEvent<HTMLButtonElement>) => {
    const target = e.currentTarget;
    target.classList.toggle("hidden");
    ApexCharts.exec(options.chart.id, "toggleSeries", target.value);
  };
  const { change } = props;

  const [bots, setBots] = useState([]);
  const [selectedBot, setSelectedBot] = useState<any>();

  function getLastMonth() {
    // Get today's date
    const today = new Date();

    // Calculate the date 30 days ago
    const lastMonthDate = new Date(today);
    lastMonthDate.setDate(today.getDate() - 30);

    // Format the dates as "dd/mm"
    const todayFormatted = formatDate(today);
    const lastMonthFormatted = formatDate(lastMonthDate);

    // Create the result string
    const result = `del ${lastMonthFormatted} hasta ${todayFormatted}`;

    return result;
  }

  // Helper function to format a date as "dd/mm"
  function formatDate(date: any) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    return `${day}/${month}`;
  }

  async function getBots(offset: number, owner_id: string) {
    const response: any = await API.get(`/bots?owner_id=${owner_id}&select=name&select=id&offset=${offset}`);
    // console.log("response en getNumbers", response);

    return response.data;
  }

  async function getAllBots(owner: string) {
    let response_data = await getBots(0, owner);

    let results = response_data.data;
    if (response_data.pagination.total > response_data.data.length) {
      const offset_increment = response_data.pagination.limit ?? response_data.data.length;
      let offset = offset_increment;
      let promises = [];
      while (offset < response_data.pagination.total) {
        promises.push(getBots(offset, owner));
        offset += offset_increment;
      }
      for (const promise of promises) {
        results.push(...(await promise).data);
      }
    }

    // console.log("botList >>", results);
    setBots(results);
    if (results.length) {
      setSelectedBot(results[0]);
      change(results[0].id);
      let dataUtil = JSON.parse(JSON.stringify(data));
      const seriesDataUtil = await getLastMonthData(+new Date() - 30 * 86400000, 31, results[0].id);
      dataUtil.series[0].data = seriesDataUtil;
      dataUtil.options.yaxis[0].max = findLargestAndRound(seriesDataUtil);

      setUpdatedData(dataUtil);
    }

    return results;
  }

  const data = {
    options: {
      chart: {
        id: "calls-chart",
        height: 480,
        type: "line",
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 1.5,
      },
      fill: {
        type: "solid",
        opacity: 1,
      },
      grid: {
        borderColor: "#485e9029",
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      xaxis: {
        type: "datetime",
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
        },
        tickAmount: 30,
        labels: {
          style: {
            fontSize: "9px",
            fontFamily: "Inter UI",
            fontWeight: 500,
            cssClass: "apexcharts-xaxis-label",
          },
        },
      },
      yaxis: [
        {
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          min: 0,
          max: 100,
          tickAmount: 6,
          decimalsInFloat: false,
          labels: {
            style: {
              colors: ["#67788e"],
              fontSize: "9px",
              fontFamily: "Inter UI",
              fontWeight: 500,
              cssClass: "apexcharts-xaxis-label",
            },
          },
        },
      ],
      colors: ["#69b2f8"],
      legend: {
        show: false,
      },
      tooltip: {
        enabled: true,
      },
      plotOptions: {
        bar: {
          columnWidth: "55%",
        },
      },
    },
    series: [
      {
        name: "Calls",
        type: "column",
        data: [],
      },
    ],
  };

  const [updatedData, setUpdatedData] = useState(data);

  async function handleSelect(e: any) {
    const botID = e.target.value;
    setSelectedBot(botID);
    change(botID);
    let dataUtil = JSON.parse(JSON.stringify(data));
    const seriesDataUtil = await getLastMonthData(+new Date() - 30 * 86400000, 31, botID);
    dataUtil.series[0].data = seriesDataUtil;
    dataUtil.options.yaxis[0].max = findLargestAndRound(seriesDataUtil);
    setUpdatedData(dataUtil);
  }

  function getNextDayISODateFromTimestamp(timestamp: number) {
    const date = new Date(timestamp);
    date.setDate(date.getDate() + 1);
    date.setHours(0, 0, 0, 0);
    return date.toISOString();
  }
  function timestampToISODateAtStartOfDay(timestamp: number) {
    const date = new Date(timestamp);
    date.setHours(0, 0, 0, 0);
    return date.toISOString();
  }

  function findLargestAndRound(arr: any) {
    let largest = -Infinity; // Initialize the largest number as negative infinity

    // Loop through the array to find the largest number from the second position
    for (let i = 0; i < arr.length; i++) {
      const value = arr[i][1]; // Get the number from the second position

      if (value > largest) {
        largest = value;
      }
    }

    if (largest < 5) {
      return 5;
    }
    if (largest < 10) {
      return 10;
    }

    // Round the largest number to the nearest multiple of 50
    const rounded = Math.ceil(largest / 50) * 50;

    return rounded;
  }

  async function getCalls(date: number, botId: string) {
    const endpoint = `/calls?date_from=${timestampToISODateAtStartOfDay(date)}&date_to=${getNextDayISODateFromTimestamp(date)}&bots_ids=${botId}&limit=1`;

    const promise = await API.get(endpoint);
    // console.log("PROMISE>>", promise.data.pagination.total);
    return promise.data.pagination.total;
  }

  async function getLastMonthData(baseval: number, count: number, botId: string) {
    const promises = [];

    let i = 0;
    let value = baseval;

    while (i < count) {
      promises.push(getCalls(value, botId)); // Add the promise to the array
      value += 86400000;
      i += 1;
    }

    // Use Promise.all to concurrently await all promises
    const results = await Promise.all(promises);

    // Process the results and create the final series
    const series = results.map((y, index) => [baseval + 86400000 * index, y]);
    // console.log("SERIESSS>", series);

    return series;
  }

  useEffect(() => {
    if (customer) {
      getAllBots(customer.id);
    }
  }, [customer]);
  return (
    <Card>
      <StyledHeader>
        <StyledHeaderLeft>
          <SectionTitle title="Historial de llamadas" desc={getLastMonth()} />
        </StyledHeaderLeft>
        {/* <StyledList> */}
        <select onChange={handleSelect} value={selectedBot?.id}>
          {bots.map((bot: any) => (
            <option key={bot.id} value={bot.id}>
              {bot.name} ({bot.id})
            </option>
          ))}
        </select>
        {/* {series.map((sr, i) => {
            const name = sr.name.split(" ");
            return (
              <StyledListItem key={sr.name} value={sr.name} onClick={chartToggle}>
                <StyledBullet bg={options.colors[i]} />
                <StyledListText>
                  {name[0]} <span>{name[1]}</span>
                </StyledListText>
              </StyledListItem>
            );
          })} */}
        {/* </StyledList> */}
      </StyledHeader>
      <CardBody pt={[0, 0]}>
        <StyledChart>
          <ApexLineChart options={updatedData.options} series={updatedData.series} width="100%" height="100%" />
        </StyledChart>
      </CardBody>
      {/* <CardFooter px="20px" py="15px">
        <Row gutters={20}>
          <Col col={6} sm={4} md={3} lg>
            <StyledNumb>156</StyledNumb>
            <Progress bg="malibu" height="2px" now={100} mb="10px" />
            <StyleTitle>New Tickets</StyleTitle>
            <StyledText>
              <StyledRate color="success">
                1.2% <i className="fa fa-arrow-up" />
              </StyledRate>{" "}
              than yesterday
            </StyledText>
          </Col>
          <Col col={6} sm={4} md={3} lg>
            <StyledNumb>86</StyledNumb>
            <Progress bg="malibu" height="2px" now={85} mb="10px" />
            <StyleTitle>Solved Tickets</StyleTitle>
            <StyledText>
              <StyledRate color="danger">
                -0.6% <i className="fa fa-arrow-down" />
              </StyledRate>{" "}
              than yesterday
            </StyledText>
          </Col>
          <Col col={6} sm={4} md={3} lg mt={["20px", 0]}>
            <StyledNumb>27</StyledNumb>
            <Progress bg="malibu" height="2px" now={25} mb="10px" />
            <StyleTitle>Unresolved Tickets</StyleTitle>
            <StyledText>
              <StyledRate color="success">
                0.3% <i className="fa fa-arrow-up" />
              </StyledRate>{" "}
              than yesterday
            </StyledText>
          </Col>
          <Col col={6} sm={4} md={3} lg mt={["20px", null, 0]}>
            <StyledNumb>45</StyledNumb>
            <Progress bg="malibu" height="2px" now={45} mb="10px" />
            <StyleTitle>Open Tickets</StyleTitle>
            <StyledText>
              <StyledRate color="success">
                0.3% <i className="fa fa-arrow-up" />
              </StyledRate>{" "}
              than yesterday
            </StyledText>
          </Col>
          <Col col={6} sm={4} md={3} lg mt={["20px", null, null, 0]}>
            <StyledNumb>30</StyledNumb>
            <Progress bg="malibu" height="2px" now={30} mb="10px" />
            <StyleTitle>Unassigned</StyleTitle>
            <StyledText>
              <StyledRate color="success">
                0.3% <i className="fa fa-arrow-up" />
              </StyledRate>{" "}
              than yesterday
            </StyledText>
          </Col>
        </Row>
      </CardFooter> */}
    </Card>
  );
};

export default TicketStatus;
