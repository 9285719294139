/* eslint-disable */
import { FC } from "react";
import { Card, CardBody, Media, MediaBody, ApexRadialChart, Spinner } from "@doar/components";
import { IRadial } from "@doar/shared/types";
import { StyledChart, StyledTitle, StyledDesc, StyledMinutes, StyledSec } from "./style";

type IProps = Omit<IRadial, "id">;

const RadialPercentage: FC<IProps> = ({ title, desc, min, sec, chart, spinner }) => {
  return (
    <Card>
      <CardBody position="relative">
        <Media display={["block", "flex"]} alignItems="center">
          {spinner && (
            <div style={{ marginRight: "30px" }}>
              <Spinner size="xl" />
            </div>
          )}
          {!spinner && (
            <StyledChart>
              <ApexRadialChart options={chart.options} series={chart.series} width="100%" height={140} />
            </StyledChart>
          )}
          <MediaBody mt={["20px", 0]}>
            <StyledTitle>{title}</StyledTitle>
            <StyledDesc>{desc}</StyledDesc>
            {/* {spinner && <Spinner size="sm" />} */}
            {!spinner && (
              <StyledMinutes>
                {min} <StyledSec>/ {sec}</StyledSec>
              </StyledMinutes>
            )}
          </MediaBody>
        </Media>
      </CardBody>
    </Card>
  );
};

export default RadialPercentage;
