/* eslint-disable */
import styled, { device, space } from "@doar/shared/styled";

export const StyledContentBody = styled.div`
        // overflow: hidden;

    position: relative;
    padding: 15px;
    /* height: calc(100% - 54px); */
    height: 100%;
    overflow-y: auto;
    ${device.small} {
        padding: 20px;
    }
    ${device.large} {
        padding: 25px;
        /* height: calc(100% - 60px); */
    }
    ${space}
`;
