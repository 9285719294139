/* eslint-disable */
import { FC } from "react";
import { Col } from "@doar/components";
import TicketStatus from "../../../components/dashboard-four/ticket-status";

interface IProps {
  change?: any;
}

const LeftRowOne: FC<IProps> = ({ change }) => {
  return (
    <Col col={12}>
      <TicketStatus change={change} />
    </Col>
  );
};

export default LeftRowOne;
