/* eslint-disable */
import API from "@doar/shared/methods/api";
import { Button, Checkbox, Select, Space, Table, TableProps, Tag } from "antd";
import { doc } from "firebase/firestore";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");
import { FC, useContext, useEffect, useRef, useState } from "react";
import { collection, firestore, query, where, onSnapshot, orderBy } from "src/config/firebase";
import { CustomerContext } from "src/config/userContext";
import "./styles.css";

interface DataType {
  key: string;
  started: string;
  from: string;
  assistant: string;
  status: string;
  seconds: string;
}

const LiveCallsContainer: FC = () => {
  const [botSelected, setBotSelected] = useState<string[]>([]);
  const [botList, setBotList] = useState([]);
  const [onlyLiveCalls, setOnlyLiveCalls] = useState(false);
  const [callsList, setCallsList] = useState<any[]>([]);
  const [selectedCall, setSelectedCall] = useState<any>({});
  const { customer } = useContext(CustomerContext);
  const [selectedCallInteractions, setSelectedCallInteractions] = useState<any[]>([]);
  const unsubscribeRef = useRef<(() => void)[]>([]);
  const chatContRef = useRef<HTMLDivElement>(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Helper function to chunk an array into smaller arrays
  const chunkArray = (array: string[], size: number) => {
    return array.reduce((chunks, el, i) => {
      if (i % size === 0) {
        chunks.push([el]);
      } else {
        chunks[chunks.length - 1].push(el);
      }
      return chunks;
    }, [] as string[][]);
  };

  function handleClick(callId: string) {
    // Referencia a un documento especifico en la coleccion 'calls'
    const docRef = doc(firestore, "calls", callId);

    // Cancelar la suscripcion anterior si existe
    unsubscribeRef.current.forEach((unsubscribe) => {
      unsubscribe();
    });
    unsubscribeRef.current = [];

    // Escuchar cambios en tiempo real para el documento especifico
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        // Asumiendo que 'interactions' es el campo que contiene las interacciones
        setSelectedCallInteractions(data.interactions || []);
        setCurrentIndex(data.interactions.length);
      } else {
        console.log("No such document!");
        setSelectedCallInteractions([]);
      }
    });
    unsubscribeRef.current.push(unsubscribe);
  }

  useEffect(() => {
    if (customer?.id !== "defaultCustomer" && customer?.id) {
      setCallsList([]);
      setSelectedCallInteractions([]);
      setSelectedCall({});

      API.get(`/bots/ids_names?owner_id=${customer.id}`)
        .then((res) => {
          const filteredData = res.data.data
            .filter((res: any) => !res.id.includes("survey-flexbot") && !res.id.includes("reminder-flexbot"))
            .map((bot: any) => ({
              value: bot.id,
              label: bot.name,
            }));
          if (filteredData.length) {
            setBotSelected([filteredData[0].value]);
          }
          setBotList(filteredData);
        })
        .catch((err) => console.log(err));
    }
  }, [customer]);

  useEffect(() => {
    if (botSelected.length) {
      // console.log("CHANGE IN BOTS SELECTED", botSelected);

      setCallsList([]);
      setSelectedCallInteractions([]);
      setSelectedCall({});

      const tenMinutesAgo = new Date(new Date().getTime() - 10 * 60000);
      const botChunks = chunkArray(botSelected, 30);
      const unsubscribeChunks: (() => void)[] = [];

      botChunks.forEach((botChunk) => {
        const q = query(collection(firestore, "calls"), where("origin_bot_id", "in", botChunk), where("start", ">=", tenMinutesAgo), orderBy("start", "desc"));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          querySnapshot.docChanges().forEach((change) => {
            const data = change.doc.data();

            const isCompleted = data.interactions.some((int: any) => {
              const callStatus = int.input?.call_status === "completed";
              const isHangup = int.output?.action_type === "hangup" || (Array.isArray(int.output) && int.output.some((out: any) => out.action_type === "hangup"));
              return callStatus || isHangup;
            });

            setCallsList((prevCalls) => {
              const existingIndex = prevCalls.findIndex((call: any) => call.key === change.doc.id);

              if (existingIndex === -1 && !isCompleted) {
                const newCall = {
                  key: change.doc.id,
                  from: data.source,
                  assistant: data.origin_bot_id,
                  status: "ongoing",
                  started: moment(data.start.seconds * 1000).fromNow(),
                  seconds: data.start.seconds,
                  // started: moment(data.start.seconds * 1000).format("HH:mm"),
                };
                return [newCall, ...prevCalls];
              } else if (existingIndex !== -1) {
                // Actualiza la llamada si su estado ha cambiado
                return prevCalls.map((call, index) => (index === existingIndex ? { ...call, status: isCompleted ? "completed" : "ongoing" } : call));
              }
              return prevCalls;
            });
          });
        });

        unsubscribeChunks.push(unsubscribe);
      });

      return () => {
        unsubscribeChunks.forEach((unsubscribe) => {
          unsubscribe();
        });
      };
    }
  }, [botSelected]);
  useEffect(() => {
    const interval = setInterval(() => {
      setCallsList((prevCalls) =>
        prevCalls.map((call) => ({
          ...call,
          started: call?.seconds ? moment(call.seconds * 1000).fromNow() : "",
        }))
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    return () => {
      // Cancelar la suscripcion al desmontar el componente
      unsubscribeRef.current.forEach((unsubscribe) => {
        unsubscribe();
      });
    };
  }, []);

  useEffect(() => {
    const scrollToTheBottom = () => {
      const scrollEl = chatContRef.current;
      scrollEl?.scroll({
        top: scrollEl?.scrollHeight,
        behavior: "smooth",
      });
    };
    scrollToTheBottom();
  }, [currentIndex]);

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Started",
      dataIndex: "started",
      key: "started",
    },
    {
      title: "From",
      dataIndex: "from",
      key: "from",
    },
    {
      title: "Assistant",
      dataIndex: "assistant",
      key: "assistant",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "tags",
      render: (_, { status }) => (
        <>
          <Tag color={status == "ongoing" ? "green" : "#acacac"} key={status}>
            {status.toUpperCase()}
          </Tag>
        </>
      ),
    },
  ];

  function animateBotText(text: string) {
    // Convertir el texto en un array de elementos span para animar cada letra
    if (text) {
      return text.split("").map((char, index) => (
        <span key={index} style={{ opacity: 0, animation: `fadeIn 0.25s ease forwards ${index * 0.025}s` }}>
          {char}
        </span>
      ));
    } else return "";
  }

  function removeSSMLTags(text: any) {
    // Regular expression pattern to match SSML tags
    const ssmlRegex = /<[^>]+>/g;

    // Check if the text contains any SSML tags
    if (ssmlRegex.test(text)) {
      // Remove SSML tags from the text using regex
      const plainText = text.replace(ssmlRegex, "");
      return plainText;
    }

    // If no SSML tags found, return the text as is
    return text;
  }

  // function checkTransfer1(int: any) {
  //   let text = "";
  //   if (int?.output[0]?.action_type == "dial") {
  //     text = `Llamada transferida al ${int.output[0].action_data}`;
  //   } else if (int?.output[1]?.action_type == "dial") {
  //     text = `Llamada transferida al ${int.output[1].action_data}`;
  //   }
  //   return text;
  // }

  function checkTransfer(int: any) {
    let text = "";
    int.output.forEach((output: any) => {
      if (output.action_type === "dial" || output.action_type === "sip:refer") {
        text = `Llamada transferida al ${output.action_data}`;
      }
    });
    return text;
  }

  function filterLive(list: any) {
    if (onlyLiveCalls) {
      return list.filter((call: any) => call.status == "ongoing");
    } else {
      return list;
    }
  }
  function getText(interaction: any) {
    let userText = "";
    let botText = "";

    const audioUrl = interaction?.output?.flatMap((out: any) => {
      if (Array.isArray(out?.action_data)) {
        return out?.action_data
          ?.map((act: any) => {
            const match = act?.match(/<audio src="([^"]+)"/);
            return match ? match[1] : null;
          })
          .filter((url: any) => url !== null);
      }
      return [];
    });

    if (interaction.input.reason === "timeout") {
      userText = "-- SIN RESPUESTA --";
    } else if (interaction.input.call_status === "completed") {
      userText = "callFinished";
    } else if (interaction.input.user_input !== null && interaction.input.user_input) {
      userText = interaction.input.user_input;
    }

    if (Array.isArray(interaction?.output)) {
      for (let i = 0; i < interaction.output.length; i++) {
        const actionData = interaction.output[i]?.action_data;
        if (actionData && interaction.output[i]?.action_type !== "sip:refer" && interaction.output[i]?.action_type !== "dial") {
          if (Array.isArray(actionData)) {
            botText += removeSSMLTags(actionData.join(" "));
          } else {
            botText += removeSSMLTags(actionData);
          }
        }
      }
    }

    // if (interaction?.output[0]?.action_data) {
    //   if (Array.isArray(interaction?.output[0]?.action_data)) {
    //     botText = removeSSMLTags(interaction?.output[0]?.action_data[0]);
    //   } else {
    //     botText = removeSSMLTags(interaction?.output[0]?.action_data);
    //   }
    // } else if (interaction?.output[1]?.action_data) {
    //   if (Array.isArray(interaction?.output[1]?.action_data)) {
    //     botText = removeSSMLTags(interaction?.output[1]?.action_data[0]);
    //   } else {
    //     botText = removeSSMLTags(interaction?.output[1]?.action_data);
    //   }
    // }
    if (audioUrl.length) {
      botText = "audioURL:/:/" + audioUrl[0];
    }

    botText = botText.replaceAll("/jambonz-call", "");
    return {
      user: userText,
      bot: botText,
    };
  }

  const handleSelectAll = () => {
    const allBots = botList.map((bot: any) => bot.value);
    setBotSelected(allBots);
  };

  const handleClearAll = () => {
    setBotSelected([]);
  };

  return (
    <div id="page-wrapper" className="liveCallsContainer">
      <div className="left-side-container">
        <div className="botSelectContainer">
          <h5>Select assistants</h5>
          <Select
            mode="multiple"
            value={botSelected}
            showSearch
            style={{ width: "100%" }}
            placeholder="Select assistant"
            optionFilterProp="children"
            filterOption={(input, option: any) => (option?.label?.toLowerCase() ?? "").includes(input?.toLowerCase())}
            filterSort={(optionA, optionB: any) => (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())}
            options={botList}
            onSelect={(e) => setBotSelected([...botSelected, e])}
            onDeselect={(e) => setBotSelected(botSelected.filter((bot) => bot !== e))}
          />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div className="hoverMouse" style={{ color: "rgb(93 130 252)", marginRight: "5px" }} onClick={handleSelectAll}>
              Select all
            </div>
            <div className="hoverMouse" style={{ color: "#acacac" }} onClick={handleClearAll}>
              Clear
            </div>
          </div>
        </div>
        {botSelected.length > 0 && (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <div style={{ display: "flex", width: "66%", justifyContent: "space-evenly" }}>
              <div style={{ marginRight: "20px", backgroundColor: "rgb(250, 250, 250)", padding: "20px", borderRadius: "16px", width: "150px" }}>
                <div style={{ fontSize: "30px", display: "flex", alignItems: "center" }}>
                  {callsList.reduce((count, call) => {
                    if (call.status === "ongoing") {
                      return count + 1;
                    }
                    return count;
                  }, 0)}
                  <svg style={{ marginLeft: "10px" }} width="30" height="30" viewBox="0 0 38 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M25.4164 3.34961L26.9459 3.76286C28.8163 4.26902 30.5214 5.25673 31.8911 6.62749C33.2607 7.99824 34.2471 9.70413 34.7517 11.575L35.1634 13.1029L32.1075 13.9279L31.6943 12.3999C31.3337 11.0636 30.629 9.84509 29.6505 8.86606C28.672 7.88702 27.4539 7.18164 26.1178 6.82028L24.5899 6.40544L25.4164 3.34961ZM1.58402 3.68686H15.169L17.5234 14.2794L14.5784 17.2244C16.3092 19.9199 18.6006 22.2107 21.2965 23.9409L24.2415 20.9974L34.834 23.3519V36.9369H33.2507C27.1555 36.9463 21.1883 35.1886 16.0715 31.8765C12.2989 29.4349 9.08599 26.222 6.64436 22.4494C3.33234 17.3326 1.57463 11.3654 1.58402 5.27019V3.68686ZM4.79344 6.85353C5.05981 11.7935 6.61502 16.5776 9.30436 20.7299C11.5026 24.126 14.3949 27.0183 17.791 29.2165C21.9432 31.906 26.7274 33.4612 31.6674 33.7274V25.8915L25.2501 24.4665L21.8079 27.9103L20.7582 27.3134C16.7725 25.0482 13.4727 21.7484 11.2075 17.7627L10.6106 16.7129L14.0544 13.2708L12.6294 6.85353H4.79344ZM23.9692 8.69811L25.4987 9.11136C26.4339 9.36444 27.2865 9.85829 27.9713 10.5437C28.6561 11.2291 29.1493 12.082 29.4016 13.0174L29.8133 14.5454L26.7574 15.3703L26.3442 13.8424C26.2359 13.4414 26.0244 13.0759 25.7307 12.7822C25.4371 12.4886 25.0715 12.2771 24.6706 12.1688L23.1427 11.7555L23.9692 8.69811Z"
                      fill="#94ACFC"
                    />
                  </svg>
                </div>
                <div style={{ color: "#7f7f7f", fontWeight: 600 }}>LIVE CALLS</div>
              </div>
              <div style={{ marginRight: "20px", backgroundColor: "rgb(250, 250, 250)", padding: "20px", borderRadius: "16px", width: "150px" }}>
                <div style={{ fontSize: "30px", display: "flex", alignItems: "center" }}>
                  {botSelected.length}{" "}
                  <svg style={{ marginLeft: "10px" }} width="38" height="38" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.9997 13.8542V7.1875H13.333" stroke="#94ACFC" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path
                      d="M29.9993 13.8535H9.99935C8.1584 13.8535 6.66602 15.3459 6.66602 17.1868V30.5202C6.66602 32.3611 8.1584 33.8535 9.99935 33.8535H29.9993C31.8403 33.8535 33.3327 32.3611 33.3327 30.5202V17.1868C33.3327 15.3459 31.8403 13.8535 29.9993 13.8535Z"
                      stroke="#94ACFC"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path d="M3.33301 23.8542H6.66634M33.333 23.8542H36.6663M24.9997 22.1875V25.5208M14.9997 22.1875V25.5208" stroke="#94ACFC" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
                <div style={{ color: "#7f7f7f", fontWeight: 600 }}>BOTS SELECTED</div>
              </div>
            </div>
            <div style={{ width: "34%", display: "flex", flexDirection: "column", alignItems: "center" }}>
              <div style={{ marginRight: "5px", fontSize: "15px", fontWeight: 600 }}>Waiting for calls</div>
              <div className="dots-container">
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
              </div>
            </div>
          </div>
        )}

        <Checkbox style={{ margin: "15px 15px 0 15px" }} checked={onlyLiveCalls} onChange={() => setOnlyLiveCalls((prev) => !prev)}>
          Show only live calls
        </Checkbox>

        <Table
          className="callsTable"
          onRow={(record, rowIndex) => {
            return {
              onMouseEnter: (event) => {
                event.currentTarget.style.cursor = "pointer";
              },
              onMouseLeave: (event) => {
                event.currentTarget.style.cursor = "default";
              },
              onClick: () => {
                handleClick(record.key);
                setSelectedCall(record);
              },
            };
          }}
          columns={columns}
          dataSource={filterLive(callsList)}
        />
      </div>
      <div className="right-side-container">
        {JSON.stringify(selectedCall) !== "{}" && (
          <div>
            <div className="chatHeader">
              <div className="headerComp">
                <div className="headerTitle">Client</div>
                <div>{selectedCall?.from}</div>
              </div>
              <div className="headerComp">
                <div className="headerTitle">Assistant</div>
                <div>{selectedCall?.assistant}</div>
              </div>
              <div className="headerComp">
                <div className="headerTitle">Started at</div>
                <div>{moment(selectedCall?.seconds * 1000).format("HH:mm")}</div>
                {/* <div>{selectedCall?.started}</div> */}
              </div>
              <div className="headerComp">
                <div className="headerTitle">Status</div>
                <div>{callsList.find((call) => call.key == selectedCall?.key)?.status}</div>
              </div>
            </div>

            <div className="chatCont1">
              <div style={{ height: "100px" }}></div>
              <div className="contenedorChats" ref={chatContRef}>
                {selectedCallInteractions.map((int: any, index: number) => (
                  <div key={index}>
                    <div>
                      {getText(int).user !== "" && getText(int).user !== "callFinished" && (
                        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                          <div className="usrMessage">{getText(int).user}</div>
                        </div>
                      )}
                      {getText(int).user == "callFinished" && (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <div className="endMessage">La llamada ha terminado</div>
                        </div>
                      )}
                      {getText(int).bot !== "" &&
                        !getText(int).bot?.includes("audioURL:/:/") &&
                        (index === selectedCallInteractions.length - 1 ? <div className="btMessage">{animateBotText(getText(int).bot)}</div> : <div className="btMessage">{getText(int).bot}</div>)}
                      {getText(int).bot?.includes("audioURL:/:/") && (
                        <div>
                          <audio style={{ borderRadius: "40px", border: "1px solid black" }} controls src={getText(int).bot.split(":/:/")[1]} />
                        </div>
                      )}

                      {checkTransfer(int) !== "" && (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <div className="trfMessage">{checkTransfer(int)}</div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                <div style={{ height: "50px", color: "white", fontSize: "0" }}>|</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LiveCallsContainer;
