/* eslint-disable */
/* eslint-disable jsx-a11y/media-has-caption */
import { Avatar, AvatarInitial, Media } from "@doar/components";
import image from "@doar/shared/images/logo/logo-ruedita.png";
import { FC } from "react";
import {
    StyledDivider,
    StyledItemBody,
    StyledItemBodyReply
} from "./style";

interface IProps {
    status?: "online" | "offline";
    image?: string;
    bg?: string;
    name: string;
    time: string;
    content: string;
    bot?: boolean;
    event?: boolean;
    reply?: boolean;
}

const Item: FC<IProps> = ({
    status,
    bg,
    time,
    content,
    bot,
    reply,
    name,
    event,
}) => {
    return (
        <div>
            {!reply && !event && (
                <Media>
                    <Avatar size="sm" shape={bot ? "square" : "circle"}>
                        {bot && <img src={image} alt={name} />}
                        {!bot && (
                            <AvatarInitial bg={bg}>
                                {name.substring(0, 1)}
                            </AvatarInitial>
                        )}
                    </Avatar>
                    <StyledItemBody>
                        <h6>
                            {name} <small>{time}</small>
                        </h6>
                        {content.includes("https") && (
                            <audio controls src={content}>
                                Audio
                            </audio>
                        )}
                        {!content.includes("https") && (
                            <div
                                dangerouslySetInnerHTML={{ __html: content }}
                            />
                        )}
                    </StyledItemBody>
                </Media>
            )}
            {reply && !event && (
                <Media>
                    <StyledItemBodyReply>
                        <h6>
                            {name} <small>{time}</small>
                        </h6>
                        {content === "-- SIN RESPUESTA --" && (
                            <StyledDivider>{content}</StyledDivider>
                        )}
                        {content !== "-- SIN RESPUESTA --" && (
                            <div
                                dangerouslySetInnerHTML={{ __html: content }}
                            />
                        )}
                    </StyledItemBodyReply>
                    <Avatar size="sm" shape={bot ? "square" : "circle"}>
                        {bot && <img src={image} alt={name} />}
                        {!bot && (
                            <AvatarInitial bg={bg}>
                                {name.substring(0, 1)}
                            </AvatarInitial>
                        )}
                    </Avatar>
                </Media>
            )}

            {event && (
                <StyledDivider>
                    <div className="line" />
                    {content} <div className="line" />
                </StyledDivider>
            )}
        </div>
    );
};

Item.defaultProps = {
    bg: "dark",
};

export default Item;
