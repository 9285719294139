/* eslint-disable */
import { FC } from "react";
import LiveCallsContainer from "src/containers/apps/live-calls/index";
import Content from "../../layouts/layout-02/content";
import ContentBody from "../../layouts/layout-02/content-body";
import ContentHeader from "../../layouts/layout-02/content-header";

import SEO from "../../components/seo";
import useSidebarMargin from "src/layouts/useSidebarMargin";

const LiveCalls: FC = () => {
  const marginLeft = useSidebarMargin();

  return (
    <div style={{ marginLeft }}>
      <ContentHeader></ContentHeader>
      <SEO />
        <ContentBody container p={["0px", "0px"]}>
          <LiveCallsContainer />
        </ContentBody>
    </div>
  );
};

export default LiveCalls;
