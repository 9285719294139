/* eslint-disable */
import styled, { css, height, themeGet } from "@doar/shared/styled";
import { Media } from "@doar/components";

export const StyledChatWrap = styled.div`
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  overflow-y: scroll;
  height: calc(100vh - 258px);
  border-top: 1px solid ${themeGet("colors.border")};
  flex: 1 1 auto;
`;

interface IProps1 {
  winHeight: number;
}
interface IProps {
  $active: boolean;
}
export const StyledChatList = styled.div<IProps1>`
${({ winHeight }) => css`
  height: calc(${winHeight}px - 282px);
`}}`;

export const StyledChatItem = styled(({ ...rest }) => <Media {...rest} />)<IProps>`
  padding: 8px 10px;
  color: #f5f6fa;
  border-radius: 0.25rem;
  cursor: pointer;
  align-items: center;
  flex: 0.5;

  &:hover,
  &:focus {
    h6 {
      color: #0168fa;
    }
    background-color: ${themeGet("colors.whisper")};
  }
  ${({ $active }) =>
    !!$active &&
    css`
      background-color: #f5f6fa;
    `}
  ${(props) =>
    props.theme.name === "dark" &&
    css`
      .avatar:after {
        box-shadow: 0 0 0 2px ${themeGet("colors.gray900")};
      }
      &:hover {
        background-color: ${themeGet("colors.gray900")};
        .avatar:after {
          box-shadow: 0 0 0 2px ${themeGet("colors.gray900")};
        }
      }
    `}
    ${(props) =>
    props.theme.name === "dark" &&
    !!props.$active &&
    css`
      background-color: ${themeGet("colors.gray900")};
      .avatar::after {
        box-shadow: 0 0 0 2px ${themeGet("colors.gray900")};
      }
    `}
`;
