/* eslint-disable */

import { FC, useContext, useEffect, useState } from "react";
import { CustomerContext, UserContext } from "src/config/userContext";
import { Step1Wizard, Step2Wizard, Step3Wizard, Step4Wizard, Step4BisWizard, StepSummaryWizard } from "./steps";
import { collection, dbWisecrack, firestore, orderBy, query } from "src/config/firebase";
import { v4 as uuidv4 } from "uuid";

import API from "@doar/shared/methods/api";
import "./styles.css";
import { doc, setDoc } from "firebase/firestore";
import FUNAPI from "@doar/shared/methods/webfun-api";
interface IProps {
  navigateTo?: any;
  handleFinish?: any;
}

const WhatsAppWizardContainer: FC<IProps> = (props) => {
  const { handleFinish } = props;
  const { customer, customerUpdate } = useContext(CustomerContext);
  const { user } = useContext(UserContext);
  const [contentToRender, setContentToRender] = useState(<div />);
  const [history, setHistory] = useState<any>([]);
  const [realCurrent, setRealCurrent] = useState<any>("step-1-wizard");
  const [realCustomer, setRealCustomer] = useState<any>(null);

  let whatsappConfig: any = {
    phone_type: "another_number",
    phone: "",
    has_whatsapp: "personal_use",
    api_provider: "dialog",
    delete_account: "reset",
    can_receive_sms: false,
  };
  const [config, setConfig] = useState<any>(whatsappConfig);

  function deepCopyByCami(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  function handleChange(key: string | string[], value?: any) {
    // console.log("CHANGE key >", key);
    // console.log("CHANGE value >", value);

    let configUtil = deepCopyByCami(config);

    const keyPath = Array.isArray(key) ? key.join(".") : key;

    const setNestedValue = (path: string, value: any, obj: any) => {
      const keys = path.split(".");
      const lastKey = keys.pop();
      const lastObj = keys.reduce((o, k) => (o[k] = o[k] || {}), obj);
      if (lastKey) {
        lastObj[lastKey] = value;
      }
    };

    if (typeof keyPath === "string" && keyPath.includes(".")) {
      setNestedValue(keyPath, value, configUtil);
    } else {
      configUtil[keyPath] = value;
    }

    if (key === "phone_type" && value === "new_number") {
      configUtil = {
        phone_type: "new_number",
        has_whatsapp: "business_whats",
      };
    }

    setConfig(configUtil);
  }

  function patchCustomer(current: string, configuration: any) {
    let customerUtil = deepCopyByCami(realCustomer);
    customerUtil["onboarding"] = { config: configuration, entry_step: current, status: "pending" };
    // console.log("REAL CUSTOMER PATCH", customerUtil);
    API.put(`/customers/${realCustomer.id}`, customerUtil)
      .then((res) => {
        // console.log("API UPDATE CUSTOMER", res);
        customerUpdate(res.data.data);
      })
      .catch((err) => console.log(err));
  }

  function handleNext(current: string, next: any) {
    // console.log("current >", current, "next >", next);

    setRealCurrent(next);
    // console.log("real current", realCurrent);
    let configUtil = deepCopyByCami(config);
    let historyUtil = deepCopyByCami(history);
    historyUtil.push(current);
    configUtil["history"] = historyUtil;
    setHistory(historyUtil);

    if (realCustomer) {
      patchCustomer(next, configUtil);
    }
  }

  async function handleFinishWizard() {
    // console.log("Finish");
    // console.log("Final config", config);

    const data = await sendNotificationsAndSave();

    handleFinish(data);

    setConfig({
      phone_type: "another_number",
      phone: "",
      has_whatsapp: "personal_use",
      delete_account: "reset",
    });
    setRealCurrent("step-1-wizard");
  }
  async function sendNotificationsAndSave() {
    let status = "ready_for_onboarding";
    // let status = "Ready for 360D onboarding";
    if ((config.has_whatsapp !== "personal_use" && config.has_whatsapp !== "business_whats") || config.phone_type == "new_number" || (config.phone_type == "another_number" && !config.can_receive_sms)) {
      status = "in_review";
      // status = "Need assistance";
    } else if ((config.has_whatsapp == "personal_use" || config.has_whatsapp == "business_whats") && config.delete_account == "not_sure") {
      status = "in_review";
      // status = "Need assistance";
    }

    let whatsappType = "";

    switch (config.has_whatsapp) {
      case "personal_use":
        whatsappType = "Personal";
        break;
      case "business_whats":
        whatsappType = "Business";
        break;
      case "whats_business_api":
        let whatsAPI = "360 Dialog";
        if (config.api_provider == "cloudApi") {
          whatsAPI = "Cloud API";
        } else if (config.api_provider == "other") {
          whatsappType = "OTHER API - " + config.other_API;
        }
        break;
      case "no_whats_this_number":
        whatsappType = "No whatsapp in this number";
        break;
      case "not_sure":
        whatsappType = "No sure - need help";
        break;

      default:
        break;
    }

    let number = "";
    if (config.phone_type == "new_number") {
      number = "New number";
    } else {
      number = config.phone;
    }

    let canSMS = "";
    if (config.can_receive_sms) {
      canSMS = "YES";
    } else {
      canSMS = "NO";
    }
    let resetWhatsapp = "";
    if (config.delete_account == "reset") {
      resetWhatsapp = "Ready for reset account";
    } else {
      resetWhatsapp = "Not sure - need help";
    }

    const blockUtil = [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: "--------------------------------------------------------------------",
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: "*USER*\n" + user.user.name + " - " + user.user.email,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: "*CUSTOMER*\n" + customer.name + " - (" + customer.id + ")",
        },
      },

      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: "*STATUS*\n" + status,
        },
      },

      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: "*NUMBER*\n" + number,
        },
      },
      config.phone_type == "another_number"
        ? {
            type: "section",
            text: {
              type: "mrkdwn",
              text: "*CAN RECEIVE SMS OR CALL*\n" + canSMS,
            },
          }
        : null,
      config.phone_type == "another_number"
        ? {
            type: "section",
            text: {
              type: "mrkdwn",
              text: "*WHATSAPP ACC TYPE*\n" + whatsappType,
            },
          }
        : null,
      config.has_whatsapp == "personal_use" || config.has_whatsapp == "business_whats"
        ? {
            type: "section",
            text: {
              type: "mrkdwn",
              text: "*RESET WHATSAPP*\n" + resetWhatsapp,
            },
          }
        : null,

      // {
      //   type: "actions",
      //   elements: [
      //     {
      //       type: "button",
      //       text: {
      //         type: "plain_text",
      //         text: "Go to CDR",
      //         emoji: true,
      //       },
      //       url: "https://admin.bookline.io/cdr/$CONVERSATION_ID",
      //       style: "primary",
      //     },
      //   ],
      // },
    ].filter((block) => block != null);

    const payload = {
      blocks: blockUtil,
    };

    // SAVE REQUEST IN DB
    const id = uuidv4();

    let data: any = {
      id,
      owner_id: customer.id,
      channel_type: "WHATSAPP",
      request: {
        phone: number,
        status,
        whatsapp_type: config?.has_whatsapp,
        reset_account: config?.delete_account ? "reset" : "not_sure",
        created_at: new Date(),
      },
    };

    if (config.has_whatsapp == "personal_use" || config.has_whatsapp == "business_whats") {
      data.request["reset_account"] = config?.delete_account ? "reset" : "not_sure";
    }

    if (config?.has_whatsapp == "whats_business_api") {
      if (config?.api_provider === "other") {
        data.request["whats_business_api"] = config.other_API;
      } else {
        data.request["whats_business_api"] = config.api_provider;
      }
    }

    const docRef = doc(dbWisecrack, "channels", id);
    // const docRef = doc(dbWisecrack, "whatsapp_config", id);
    await setDoc(docRef, data);

    // SEND SLACK NOTIFICATION

    const webhookURL = `https://hooks.slack.com/services/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`;

    try {
      const response = await fetch(webhookURL, {
        method: "POST",
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        console.log("Slack notification sent");
      } else {
        console.error("Error en la respuesta:", response.statusText);
      }
    } catch (error) {
      console.error("Error al enviar notificación a Slack:", error);
    }

    // TODO: CREAR CASE EN SALESFORCE

    try {
      // const salesforcePayload = {
      //   customer_id: customer.id,
      //   customer_name: customer.name,
      //   user_name: user.user.name,
      //   user_email: user.user.email,
      //   status: status == "in_review" ? "Need assistance" : "Ready for 360D onboarding",
      //   number: number,
      //   sms: canSMS,
      //   whatsapp_type: whatsappType,
      //   reset_account: resetWhatsapp,
      // };

      const payload = {
        subject: "New WhatsApp Channel Request",
        status: "New",
        origin: "Web",
        priority: "Medium",
        description: `
        User id: ${user.user.id}
        User name: ${user.user.name}
        User email: ${user.user.email}
        User phone: ${JSON.stringify(user.user.phone)}
        Customer id: ${data.owner_id}
        Request data:
          Phone: ${data.request.phone}
          Status: ${data.request.status}
          Whatsapp type: ${data.request.whatsapp_type}${data.request.whats_business_api ? ` - ${data.request.whats_business_api}` : ""}
          Ready to reset account: ${data.request.reset_account}
        `,
      };

      FUNAPI.post("/salesforce/case", payload);
    } catch (error) {
      console.log(error);
    }

    // SEND EMAIL NOTIFICATION

    const emailPayload = {
      template_id: "d-abf4114688224cad94981aca05078fd9",
      dynamic_template_data: {
        customer_id: customer.id,
        customer_name: customer.name,
        user_name: user.user.name,
        user_email: user.user.email,
        status: status == "in_review" ? "Need assistance" : "Ready for 360D onboarding",
        number: number,
        sms: canSMS,
        whatsapp_type: whatsappType,
        reset_account: resetWhatsapp,
      },
      from_email: "no-reply@bookline.io",
      to_emails: process.env.REACT_APP_ENVIRONMENT === "development" ? ["pedro@bookline.ai"] : ["soporte@bookline.ai"],
    };

    try {
      const res = await API.post("/emails/new", emailPayload);
      if (res.status >= 200 && res.status < 300) {
        console.log("AXIOS EMAIL", res);
      }
    } catch (err) {
      console.log(err);
    }
    return data;
  }

  function renderStep(id: any) {
    switch (id) {
      case "step-1-wizard":
        // return <Step1Wizard prefill={config} customer={customer.onboarding.config} handleChange={handleChange} handleNext={handleNext} />;
        return <Step2Wizard prefill={config} customer={customer.onboarding.config} handleChange={handleChange} handleNext={handleNext} />;
      case "step-2-wizard":
        return <Step2Wizard prefill={config} customer={customer.onboarding.config} handleChange={handleChange} handleNext={handleNext} />;
      case "step-3-wizard":
        return <Step3Wizard prefill={config} customer={customer.onboarding.config} handleChange={handleChange} handleNext={handleNext} />;
      case "step-4-wizard":
        return <Step4Wizard prefill={config} customer={customer.onboarding.config} handleChange={handleChange} handleNext={handleNext} />;
      case "step-4-bis-wizard":
        return <Step4BisWizard prefill={config} customer={customer.onboarding.config} handleChange={handleChange} handleNext={handleNext} />;
      case "step-summary-wizard":
        return <StepSummaryWizard prefill={config} customer={customer.onboarding.config} handleChange={handleChange} handleNext={handleNext} handleFinishWizard={handleFinishWizard} />;
      default:
        break;
    }
  }

  useEffect(() => {
    // console.log("CONFIG >>>>", config);
    const stepUtil: any = renderStep(realCurrent);
    setContentToRender(stepUtil);

    // console.log("USER >>>", user);
  }, [realCurrent, config]);

  return <div className="wizardPage">{contentToRender}</div>;
};
export default WhatsAppWizardContainer;
