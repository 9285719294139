/* eslint-disable */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { FC, useContext, useEffect, useState } from "react";
import { BotsFilfter } from "src/components/inputs/bots-filter";
import { DateFilfter } from "src/components/inputs/date-filter";
import { CustomerContext } from "src/config/userContext";
import { useAppDispatch } from "src/redux/hooks";
import { setCall, setCallsArr, togglePerson } from "src/redux/slices/calls-ui";

import { Button } from "@doar/components";
import API from "@doar/shared/methods/api";

import { Input } from "antd";
import SidebarMessages from "../sidebar-messages";
import { StyledWrap } from "./style";
import "./styles.css";
import { useToasts } from "react-toast-notifications";

const { Search } = Input;

const SidebarSearch: FC = () => {
  const dispatch = useAppDispatch();
  const { customer } = useContext(CustomerContext);

  const [valueFrom, setValueFrom] = useState<Date>(new Date());
  const [valueTo, setValueTo] = useState<Date>(new Date());
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [botList, setBotList] = useState<any[]>([]);
  const [callList, setCallList] = useState<any[]>([]);
  // const [paginatedCallList, setPaginatedCallList] = useState<any[]>([]);
  const [customerBotList, setCustomerBotList] = useState<any[]>([]);
  const [defaultSelection, setDefaultSelection] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [showNumbers, setShowNumbers] = useState(false);
  const [paginationDisabled, setPaginationDisabled] = useState(false);
  const [totalCalls, setTotalCalls] = useState(0);
  // const [phoneNumber, setPhoneNumber] = useState("");
  const [pageBackup, setPageBackup] = useState<any>([]);
  const [originNumber, setOriginNumber] = useState("");
  const [destinationNumber, setDestinationNumber] = useState("");

  function timeConverter(timestamp: any) {
    let time = new Date(timestamp).toISOString();

    return time;
  }

  // function paginateCalls(array: any[], newLimit?: number) {
  //   let pageSize = Number(limit);
  //   if (newLimit) {
  //     pageSize = Number(newLimit);
  //   }
  //   let arrayUtil = [];
  //   for (let i = 0; i < array.length; i += pageSize) {
  //     const chunk = array.slice(i, i + pageSize);
  //     arrayUtil.push(chunk);
  //   }
  //   setPaginatedCallList(arrayUtil);

  //   return arrayUtil[0];
  // }

  function uriEncode(string: string) {
    // Define a regular expression to match special characters
    const specialCharsRegex = /[$&+,/:;=?@#|'<>^*()%!{}[\]"]/g;

    // Replace each matched special character with its URI code
    const uriEncodedString = string.replace(specialCharsRegex, (match) => {
      return encodeURIComponent(match);
    });

    return uriEncodedString;
  }

  function handleSubmit(valueFromUtil: Date, valueToUtil: Date, botListUtil?: string[], limitParam?: number, pagination?: boolean, phoneNumberParam?: string, phoneNumberParamDestination?: string) {
    let params = "";

    if (valueFromUtil && params.length > 0) {
      params = params.concat(`&date_from=${timeConverter(valueFromUtil)}`);
    } else if (valueFromUtil && params.length === 0) {
      params = params.concat(`?date_from=${timeConverter(valueFromUtil)}`);
    }

    if (valueToUtil && params.length > 0) {
      params = params.concat(`&date_to=${timeConverter(valueToUtil)}`);
    } else if (valueToUtil && params.length === 0) {
      params = params.concat(`?date_to=${timeConverter(valueToUtil)}`);
    }
    if (phoneNumberParam) {
      params = params.concat(`&source=${uriEncode(phoneNumberParam)}`);
    }
    if (phoneNumberParamDestination) {
      params = params.concat(`&destination=${uriEncode(phoneNumberParamDestination.replace("+", "00"))}`);
    }

    if (botListUtil && botListUtil?.length > 0) {
      params = params.concat(`&bots_ids=${botListUtil.join(",")}`);

      API.get(`/calls${params}&limit=${limitParam ? limitParam : limit}`)
        .then((res) => {

          setCallList(res.data.data);
          if (!pagination) {
            setTotalCalls(res.data.pagination.total);
          }
          dispatch(setCallsArr(res.data.data));
          dispatch(setCall(res.data.data[0]));
          dispatch(togglePerson({ person: { name: res.data.data[0]?.origin_bot_id ? res.data.data[0]?.origin_bot_id : "", status: "offline" } }));

          setSpinner(true);
          setPaginationDisabled(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      dispatch(setCallsArr([]));
      dispatch(setCall({ payload: {}, type: "" }));
      dispatch(togglePerson({ person: { name: "", status: "offline" } }));
    }
  }

  function handleLimit(limitParam: number) {
    setLimit(limitParam);

    setCount(0);
    setPageBackup([]);
    handleSubmit(valueFrom, valueTo, botList, limitParam, false, originNumber, destinationNumber);

    // dispatch(setCallsArr(paginateCalls(callList, limitParam)));
    // dispatch(setCall(paginateCalls(callList, limitParam)[0]));
    // dispatch(togglePerson({ person: { name: paginateCalls(callList, limitParam)[0]?.origin_bot_id ? paginateCalls(callList, limitParam)[0]?.origin_bot_id : "", status: "offline" } }));
  }
  function handleBots(bots: any) {
    setBotList(bots);
    if (bots.length === 0) {
      setBotList([]);
      setCallList([]);
      // setPaginatedCallList([]);
      setCount(0);
    }
    handleSubmit(valueFrom, valueTo, bots, limit, false, originNumber, destinationNumber);
  }
  function handleNumbers() {
    setShowNumbers(!showNumbers);
  }
  function handleDates(range: Date[]) {
    handleSubmit(range[0], range[1], botList, limit, false, originNumber, destinationNumber);
    setValueFrom(range[0]);
    setValueTo(range[1]);
    setCount(0);
  }
  function onSearch(phoneNumberParam: any) {
    setSpinner(false);
    setCount(0);
    setPageBackup([]);
    handleSubmit(valueFrom, valueTo, botList, limit, false, phoneNumberParam, destinationNumber);
  }
  function onSearchDestination(phoneNumberParam: any) {
    setSpinner(false);
    setCount(0);
    setPageBackup([]);
    handleSubmit(valueFrom, valueTo, botList, limit, false, originNumber, phoneNumberParam);
  }
  function handleNext(countParam: number) {
    setPaginationDisabled(true);

    setSpinner(false);

    if (pageBackup.length < countParam) {
      let pageBackupUtil = [...pageBackup];
      pageBackupUtil.push(callList[0].start);
      setPageBackup(pageBackupUtil);
    }

    handleSubmit(valueFrom, callList[callList.length - 1].start, botList, limit, true, originNumber, destinationNumber);
  }
  function add1Millisecond(countParam: any) {
    let dateUtil = countParam;

    dateUtil = new Date(new Date(dateUtil).getTime() + 1);
    return dateUtil;
  }
  function handlePrev(countParam: number) {
    setPaginationDisabled(true);
    setSpinner(false);
    handleSubmit(valueFrom, add1Millisecond(pageBackup[countParam]), botList, limit, true, originNumber, destinationNumber);
  }
  function defaultCallList(botListParam: any) {
    let tomorrow = new Date(new Date(new Date().setDate(new Date().getDate() + 1)).toDateString());
    let sevenDays = new Date(new Date(new Date().setDate(new Date().getDate() - 6)).toDateString());
    let range = [sevenDays, tomorrow];
    let first10Bots = [];
    for (let i = 0; i < 10; i += 1) {
      if (botListParam[i]) {
        first10Bots.push(botListParam[i].id);
      }
    }
    setBotList(first10Bots);
    setValueFrom(range[0]);
    setValueTo(range[1]);
    handleSubmit(range[0], range[1], first10Bots);
  }

  useEffect(() => {
    if (customer?.id !== "defaultCustomer" && customer?.id) {
      API.get(`/bots/ids_names?owner_id=${customer.id}`)
        .then((res) => {
          const filteredData = res.data.data.filter((res: any) => {
            if (!res.id.includes("survey-flexbot") && !res.id.includes("reminder-flexbot")) {
              return true;
            }
            return false;
          });
          setCustomerBotList(filteredData);
          defaultCallList(filteredData);
          setSpinner(true);
        })
        .catch((err) => console.log(err));
    } else {
      setSpinner(true);
    }
  }, [customer]);
  useEffect(() => {
    return () => {
      dispatch(setCallsArr([]));
      dispatch(setCall({ payload: {}, type: "" }));
      dispatch(togglePerson({ person: { name: "", status: "offline" } }));
    };
  }, []);

  const { addToast } = useToasts();

  useEffect(() => {
    if (!customer?.id) {
      addToast("Por favor selecciona un customer", {
        appearance: "warning",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
    }
  }, []);

  return (
    <StyledWrap>
      <div className="header">
        <DateFilfter onChangeDates={handleDates} />
        <div className="filtersContainer">
          <div style={{ width: "49%" }}>
            <BotsFilfter onChangeBots={handleBots} prefill={customerBotList} preselected={defaultSelection} />
          </div>
          <div style={{ width: "49%" }}>
            <div id="filterButton" className={`barButton ${showNumbers ? "activeFilter" : ""}  filterButton`} role="button" tabIndex={0} onClick={handleNumbers}>
              Buscar por número
            </div>
          </div>
        </div>
        {showNumbers && (
          <div className="searchByNumberDiv">
            <div className="searchNumberContainer">
              <label style={{ width: "100px" }} className="pickerLabel" htmlFor="">
                Cliente
              </label>
              <Search id="searchInp" placeholder="Buscar un número exacto" allowClear size="large" onChange={(e) => setOriginNumber(e.target.value)} onSearch={onSearch} enterButton />
            </div>
            <div className="searchNumberContainer">
              <label style={{ width: "100px" }} className="pickerLabel" htmlFor="">
                Asistente
              </label>
              <Search id="searchInp" placeholder="Buscar un número exacto" allowClear size="large" onChange={(e) => setDestinationNumber(e.target.value)} onSearch={onSearchDestination} enterButton />
            </div>
          </div>
        )}
      </div>
      <SidebarMessages spinner={spinner} />

      {callList.length > 0 && (
        <div className="paginationControls">
          Mostrar
          <select
            className="selectLimit"
            name="limit"
            id="limit"
            onBlur={() => {}}
            onChange={(e: any) => {
              handleLimit(e.target.value);
            }}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
          </select>
          <Button
            disabled={paginationDisabled}
            color="primary"
            ml="5px"
            type="button"
            onClick={() => {
              if (count > 0) {
                setCount(count - 1);
                handlePrev(count - 1);
              }
            }}
          >
            Anterior
          </Button>
          <span className="onlyBig">
            Página {count + 1} de {Math.ceil(totalCalls / limit)}
          </span>
          <span className="onlySmall">
            {count + 1} / {Math.ceil(totalCalls / limit)}
          </span>
          <Button
            disabled={paginationDisabled}
            color="primary"
            ml="5px"
            type="button"
            onClick={() => {
              if (Math.ceil(totalCalls / limit) > count + 1) {
                setCount(count + 1);
                handleNext(count + 1);
              }
            }}
          >
            Siguiente
          </Button>
        </div>
      )}
    </StyledWrap>
  );
};

export default SidebarSearch;
