/* eslint-disable */
/* eslint-disable no-lonely-if */
/* eslint-disable no-empty */
import { FC, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";
import { Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";

interface IProps {
  onChangeDates?: any;
}

export const DateFilfter: FC<IProps> = ({ onChangeDates }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [activeClass, setActiveClass] = useState("");

  const [endDate, setEndDate] = useState(null);

  const onChangeDate = (dates: [any, any]) => {
    let [start, end] = dates;
    start = new Date(start.toDateString());
    if (end) {
      end = new Date(end.setHours(23, 59, 59));
    }
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      setShowDropdown(false);
      onChangeDates([start, end]);
      setActiveClass("custom");
    }
  };

  function handleBlur() {}
  function handleClick(when: string) {
    let yesterday = new Date(new Date(new Date().setDate(new Date().getDate() - 1)).toDateString());
    let today = new Date(new Date().toDateString());
    let tomorrow = new Date(new Date(new Date().setDate(new Date().getDate() + 1)).toDateString());
    let sevenDays = new Date(new Date(new Date().setDate(new Date().getDate() - 6)).toDateString());
    let thirtyDays = new Date(new Date(new Date().setDate(new Date().getDate() - 29)).toDateString());
    let range: Date[] = [];

    if (when === "today") {
      range = [today, tomorrow];
      setActiveClass("today");
      onChangeDates(range);
      setShowDropdown(false);
    } else if (when === "yesterday") {
      range = [yesterday, today];
      setActiveClass("yesterday");
      onChangeDates(range);
      setShowDropdown(false);
    } else if (when === "sevenDays") {
      range = [sevenDays, tomorrow];
      setActiveClass("sevenDays");
      setShowDropdown(false);
      onChangeDates(range);
    } else if (when === "thirtyDays") {
      range = [thirtyDays, tomorrow];
      setActiveClass("thirtyDays");
      setShowDropdown(false);
      onChangeDates(range);
    } else if (when === "custom") {
      setActiveClass("custom");
    } else {
      setActiveClass("");
    }
  }
  useEffect(() => {
    setActiveClass("sevenDays");
  }, []);

  return (
    <div className="buttonsBar">
      <div className="buttonsBarRow">
        <div className={activeClass === "today" ? "barButton activeFilter" : "barButton"} role="button" tabIndex={0} onClick={(e: any) => handleClick("today")}>
          Hoy
        </div>
        <div className={activeClass === "yesterday" ? "barButton activeFilter" : "barButton"} role="button" tabIndex={0} onClick={(e: any) => handleClick("yesterday")}>
          Ayer
        </div>
        <div className={activeClass === "sevenDays" ? "barButton activeFilter" : "barButton"} role="button" tabIndex={0} onClick={(e: any) => handleClick("sevenDays")}>
          7D
        </div>
        <div className={activeClass === "thirtyDays" ? "barButton activeFilter" : "barButton"} role="button" tabIndex={0} onClick={(e: any) => handleClick("thirtyDays")}>
          30D
        </div>
        <div
          className={activeClass === "custom" ? "barButton activeFilter" : "barButton"}
          role="button"
          tabIndex={0}
          onClick={(e: any) => {
            handleClick("custom");
            setShowDropdown(!showDropdown);
          }}
        >
          Custom
        </div>
      </div>
      {showDropdown && (
        // cami
        <div className="customDateDiv">
          <div className="pickersDiv">
            <div className="pickerDiv" style={{ marginBottom: "8px" }}>
              <label className="pickerLabel" htmlFor="">
                From
              </label>
              <DatePicker selected={startDate} onChange={(date: any) => setStartDate(date)} showTimeSelect timeFormat="HH:mm" timeIntervals={15} dateFormat="dd/MM/yyyy, HH:mm" timeCaption="time" />
            </div>
            <div className="pickerDiv">
              <label className="pickerLabel" htmlFor="">
                To
              </label>
              <DatePicker selected={endDate} onChange={(date: any) => setEndDate(date)} showTimeSelect timeFormat="HH:mm" timeIntervals={15} dateFormat="dd/MM/yyyy, HH:mm" timeCaption="time" />
            </div>
          </div>
          <div className="searchButtonDiv">
            <Button type="primary" icon={<SearchOutlined />} onClick={() => onChangeDates([startDate, endDate])}>
              Search
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
