/* eslint-disable */
import { FC, useEffect, useState } from "react";
import { useAppSelector } from "src/redux/hooks";
import { auth } from "src/config/firebase";
import axios from "axios";

const CallAudio: FC = () => {
  const { call } = useAppSelector((state) => state.callsUI);
  const [callAudioUrl, setCallAudioUrl] = useState("");

  async function generateSignedUrl() {
    try {
      const date: any = new Date(call.payload.start);
      const formattedDate: any = date.toISOString().split("T")[0].replace(/-/g, "/");
      const isDev = process.env.REACT_APP_ENVIRONMENT === "development";
      const jambonzId = call.payload.sid;

      const url = `https://europe-west3-bookline-${isDev ? "dev" : "pro"}.cloudfunctions.net/api/storage-url?bucket=recordings-bookline-pro&path=${formattedDate}/${jambonzId}.mp3`;

      const token = await auth.currentUser?.getIdToken();
      let config = {};
      if (token) {
        config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      }

      let response = await axios.get(url, config);
      if (response.status > 199 && response.status < 300) setCallAudioUrl(response?.data?.urlSigned);
    } catch (error: any) {
      console.log(error?.response?.data);
    }
  }

  useEffect(() => {
    setCallAudioUrl("");
    if (call && call?.payload?.sid) {
      // console.log("CALL EN CallAudio component", call);
      generateSignedUrl();
    }
  }, [call]);

  return (
    <>
      {callAudioUrl && (
        <div>
          <audio style={{ width: "100%", background: "#f1f3f4" }} controls src={callAudioUrl} />
        </div>
      )}
    </>
  );
};

export default CallAudio;
