/* eslint-disable */
import esFlag from "@doar/shared/images/flags/ES.svg";

export function replaceSpecialCharacters(inputString: string) {
  const characterMap: any = {
    á: "a",
    à: "a",
    â: "a",
    ä: "a",
    å: "a",
    ã: "a",
    ç: "c",
    é: "e",
    è: "e",
    ê: "e",
    ë: "e",
    í: "i",
    ì: "i",
    î: "i",
    ï: "i",
    ñ: "n",
    ó: "o",
    ò: "o",
    ô: "o",
    ö: "o",
    ø: "o",
    õ: "o",
    ú: "u",
    ù: "u",
    û: "u",
    ü: "u",
    ÿ: "y",
    ß: "ss",
    æ: "ae",
    œ: "oe",
    þ: "th",
    ð: "dh",
    ł: "l",
    ń: "n",
    ś: "s",
    ź: "z",
    ć: "c",
    ę: "e",
    ą: "a",
    ż: "z",
    č: "c",
    š: "s",
    ž: "z",
    ř: "r",
    ů: "u",
    ľ: "l",
    ĺ: "l",
    ť: "t",
    ď: "d",
    ň: "n",
    ě: "e",
    ı: "i",
    ğ: "g",
    ş: "s",
    ț: "t",
    ș: "s",
    ķ: "k",
    ģ: "g",
    ļ: "l",
    ņ: "n",
    ħ: "h",
    đ: "d",
    ĝ: "g",
    ĥ: "h",
    ĵ: "j",
    ŝ: "s",
    ŭ: "u",
    ẽ: "e",
    ỹ: "y",
    ĩ: "i",
    ỷ: "y",
    ỳ: "y",
    ẹ: "e",
    ọ: "o",
    ụ: "u",
    ḥ: "h",
    ẓ: "z",
    ẕ: "z",
    ṭ: "t",
    ẗ: "t",
    ẁ: "w",
    ẃ: "w",
    ẅ: "w",
    ẏ: "y",
    ỵ: "y",
    ẙ: "y",
    ṣ: "s",
    ḍ: "d",
    ḻ: "l",
    ṃ: "m",
    ṅ: "n",
    ṕ: "p",
    ṙ: "r",
    ṡ: "s",
    ṫ: "t",
    ẘ: "w",
    ẍ: "x",
    ẋ: "x",
    ɔ: "o",
  };

  return inputString
    .replace(/[áàâäåãçéèêëíìîïñóòôöøõúùûüÿßæœåøþðłńśźćęążčšžřůľĺťďňěığşțșțķģļņħçđśźćęążčšžřůľĺťďňěığşțșțķģļņħçđñĝĥĵŝŭẽỹãĩỷỳẹọụḥẓẕṭẗẁẃẅỳẏỵẙṣẹḍḻṃṅṕṙṡṫẘẍẋɔæœøå]/gi, function (matchedCharacter: any) {
      return characterMap[matchedCharacter] || matchedCharacter;
    })
    .replace(/[.,:;/!"'@·$%&=?¿<>#{}`´¡€]/g, "-");
}
export const LANGUAGES = [
  { label: "Spanish", code: "es", flag: esFlag },
  { label: "English", code: "en", flag: esFlag },
  { label: "Catalan", code: "ca", flag: esFlag },
  { label: "Italian", code: "it", flag: esFlag },
  { label: "Portuguese", code: "pt", flag: esFlag },
];
