/* eslint-disable */
/* eslint-disable import/no-unresolved */
import { FC, useEffect, useState } from "react";
import { Users, DollarSign, List } from "react-feather";
import { TeamTabComponent } from "./tabs/team-tab";
import { BillingTabComponent } from "./tabs/billing-tab";
import Breadcrumb from "src/components/breadcrumb";
import "./styles.css";
import { t } from "i18next";
import PreferencesTabComponent from "./tabs/preferences-tab";
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";

interface IProps {}

const SettingsWrapper: FC<IProps> = () => {
  const { i18n } = useTranslation();
  const [activeTab, setActiveTab] = useState("1");
  const [forceRender, setForceRender] = useState(true);

  const onTabChange = (key: any) => {
    setActiveTab(key);
  };

  const tabItems = [
    {
      key: "1",
      label: t("team"),
      icon: <Users />,
    },
    {
      key: "2",
      label: t("billing"),
      icon: <DollarSign />,
    },
    {
      key: "3",
      label: t("preferences"),
      icon: <List />,
    },
  ];

  useEffect(() => {
    setForceRender(!forceRender);
  }, [i18n]);

  return (
    <div id="page-wrapper" className="settings-wrapper">
      {/* Breadcrumb y TabSelectComponent deben estar fuera de los condicionales */}
      <Breadcrumb prev={[{ text: "General", link: "/" }]} title={t("settings")} />
      <Tabs onChange={onTabChange} defaultActiveKey="1" items={tabItems} />

      {/* Team y Billing tab content */}
      {activeTab === "1" && <TeamTabComponent />}
      {activeTab === "2" && <BillingTabComponent />}
      {activeTab === "3" && <PreferencesTabComponent />}
    </div>
  );
};

export default SettingsWrapper;
