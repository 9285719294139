/* eslint-disable */
import styled, { themeGet, css } from "@doar/shared/styled";
import { MediaBody } from "@doar/components";

export const StyledGroup = styled.div`
    // min-height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .media {
        & + .media {
            margin-top: 20px;
        }
    }
`;

export const StyledGroupReply = styled.div`
    // min-height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
    .media {
        & + .media {
            margin-top: 20px;
        }
    }
`;
export const StyledGroupEvent = styled.div`
    // min-height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    .media {
        & + .media {
            margin-top: 20px;
        }
    }
`;

export const StyledDivider = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    margin: 20px 0;
    color: ${themeGet("colors.text3")};
    font-size: 10px;
    font-family: ${themeGet("fonts.interUi")};
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    .line {
        margin-left: 5px;
        margin-right: 5px;
        width: 100px;
        flex: 1;
        border-bottom: 1px solid;
    }
`;

export const StyledItemBody = styled(({ ...rest }) => <MediaBody {...rest} />)`
    margin-left: 20px;
    h6 {
        font-size: 14px;
        font-weight: 600;
        small {
            color: ${themeGet("colors.text3")};
        }
    }
    p {
        margin-bottom: 5px;
    }
    ${(props) =>
        props.theme.name === "dark" &&
        css`
            color: ${themeGet("colors.gray700")};
        `}
`;

export const StyledItemBodyReply = styled(({ ...rest }) => (
    <MediaBody {...rest} />
))`
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    align-items: flex-end;

    h6 {
        font-size: 14px;
        font-weight: 600;
        small {
            color: ${themeGet("colors.text3")};
        }
    }
    p {
        margin-bottom: 5px;
    }
    ${(props) =>
        props.theme.name === "dark" &&
        css`
            color: ${themeGet("colors.gray700")};
        `}
`;
export const StyledItemBodyEvent = styled(({ ...rest }) => (
    <MediaBody {...rest} />
))`
    margin-left: 20px;
    h6 {
        font-size: 14px;
        font-weight: 600;
        small {
            color: ${themeGet("colors.text3")};
        }
    }
    p {
        margin-bottom: 5px;
    }
    ${(props) =>
        props.theme.name === "dark" &&
        css`
            color: ${themeGet("colors.gray700")};
        `}
`;
