/* eslint-disable */
/* eslint-disable react/react-in-jsx-scope */
import styled, { themeGet, css } from "@doar/shared/styled";
import { BreadcrumbItem, Breadcrumb } from "@doar/components";

export const StyledBreadWrap = styled((props) => <Breadcrumb {...props} />)`
    background: transparent;
    margin-bottom: 10px;
    padding: 0;
`;

export const StyledBreadItem = styled((props) => <BreadcrumbItem {...props} />)`
    letter-spacing: 0.5px;
    font-size: 16px;
    a {
        color: var(--neutral-color-black);
    }
    ${({ active }) =>
        active === true &&
        css`
            color: var(--neutral-color-black);
            font-weight: bold;
        `}
    ${(props) =>
        props.theme.name === "dark" &&
        css`
            a {
                color: red;
            }
        `}
`;

export const PageHeading = styled.h4`
    letter-spacing: -0.5px;
    margin-bottom: 0px;
`;
