/* eslint-disable */
/* eslint-disable import/no-unresolved */
import { FC, useContext } from "react";
import SEO from "../components/seo";
import ContentBody from "../layouts/layout-02/content-body";
import ChannelsContainer from "src/containers/channels";
import useSidebarMargin from "src/layouts/useSidebarMargin";
import ContentHeader from "src/layouts/layout-02/content-header";

const ChannelsPage: FC = () => {
  const marginLeft = useSidebarMargin();

  return (
    <div style={{ marginLeft }}>
      <ContentHeader></ContentHeader>
      <SEO />
      <ContentBody container={false}>
        <ChannelsContainer />
      </ContentBody>
    </div>
  );
};

export default ChannelsPage;
