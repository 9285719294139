/* eslint-disable */
/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable prefer-template */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC, useContext, useEffect, useState } from "react";
import { CustomerContext, UserContext } from "src/config/userContext";
import { Avatar, AvatarInitial } from "@doar/components";
import API from "@doar/shared/methods/api";
import { useTranslation } from "react-i18next";
import { CopyOutlined } from "@ant-design/icons";
import { Input, Modal, Row, Col, message, Skeleton } from "antd";
import { StyledButton } from "../../dashboard-four/welcome-area/style";
import "./styles.css";
import { Card, Space } from "antd";
import sharedImage from "../../../../../components/src/static/share-email-image.png";

interface IProps {}

export const TeamTabComponent: FC<IProps> = () => {
  const { customer, customerUpdate } = useContext(CustomerContext);
  const { user } = useContext(UserContext);
  const [spinner, setSpinner] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [inviteLink, setInviteLink] = useState("");
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [disable, setDisable] = useState(false);
  const [userList, setUserList] = useState<any[]>([]);
  const { t } = useTranslation();

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  function handleInvite() {
    setDisable(true);
    setEmail("");
    API.post("/tokens", { data: customer.id, ttl_minutes: 1440 })
      .then((res) => {
        console.log("Response from token", res.data.token);
        setOpen(true);
        let baseURL = "";
        if (process.env.REACT_APP_ENVIRONMENT === "development") {
          baseURL = `https://app.dev.bookline.io/signup?token=${res.data.token}`;
        } else if (process.env.REACT_APP_ENVIRONMENT === "production") {
          baseURL = `https://app.bookline.io/signup?token=${res.data.token}`;
        }
        setInviteLink(baseURL);
        setDisable(false);
      })
      .catch((err) => console.log(err));
  }

  const handleCancel = () => {
    setOpen(false);
  };
  const handleSend = () => {
    const payload = {
      template_id: "d-d8c957c3ed3d4f628413cce8513af088",
      dynamic_template_data: {
        LINK: inviteLink,
      },
      from_email: "no-reply@bookline.io",
      to_emails: [email],
    };
    if (isValidEmail) {
      API.post("/emails/new", payload)
        .then((res) => {
          console.log("AXIOS EMAIL", res);
          message.success(t("email_send_success"));
          setOpen(false);
        })
        .catch((err) => {
          console.log(err);
          message.error(t("email_send_not_success"));
        });
    }
  };
  const handleCopy = () => {
    navigator.clipboard
      .writeText(inviteLink)
      .then(() => {})
      .catch(() => {});
    message.success(t("copied_to_clipboard"));
    setOpen(false);
  };

  useEffect(() => {
    setSpinner(true);

    if (customer?.id && customer?.id !== "defaultCustomer") {
      API.get(`/users?has_access_to_customer_id=${customer.id}`)
        .then((res) => {
          const usersData = res.data.data;
          if (user?.user?.role === "ADMIN") {
            setUserList(usersData);
          } else {
            const usersFilter = usersData.filter((user: any) => user.role !== "ADMIN");
            setUserList(usersFilter);
          }
          setTimeout(() => {
            setSpinner(false);
          }, 1000);
        })
        .catch((err) => console.log(err));
    } else {
      setSpinner(false);
    }
  }, [customer?.id]);

  return (
    <Space direction="vertical" size={16} className="team-tab-wrapper">
      {/* {spinner && (
        <div className="spinnerContainer">
          <Spinner className="spinner" size="xl" />
        </div>
      )} */}
        <Card
          title={t("users")}
          className="team-tab-wrapper-card"
          extra={
            <StyledButton size="sm" ml="10px" hasIcon disabled={disable} onClick={handleInvite}>
              {t("invite")}
            </StyledButton>
          }
        >
          <Row className="table-header" justify="space-between" align="middle">
            <Col xs={12} sm={16} md={16} lg={12}>
              {t("user")}
            </Col>
            {/* Columna para el usuario */}
            <Col xs={12} sm={8} md={8} lg={6} className="user-card-right-title">
              <div>
                <span className="user-phone">Phone</span> {/* Teléfono */}
              </div>
              {user?.user?.role === "ADMIN" && (
                <div>
                  <span className="user-role">{t("role")}</span> {/* Rol */}
                </div>
              )}
            </Col>
          </Row>

            <div className="user-card-list">
              {userList.map((usr: any, index: number) => (
              <Skeleton key={index} loading={spinner} avatar paragraph={{ rows: 1 }} active className="skeleton-list">
                <Card key={usr.id} className="user-card">
                  <Row justify="space-between" align="middle">
                    {/* Parte izquierda: Avatar, Nombre y Correo */}
                    <Col xs={12} sm={16} md={16} lg={12}>
                      <Row align="middle">
                        <Col className="user-card-avatar">
                          <Avatar size="sm" shape="circle">
                            <AvatarInitial key={usr.id} index={index}>
                              {usr.name.substring(0, 1)}
                            </AvatarInitial>
                          </Avatar>
                        </Col>
                        <Col>
                          <div>
                            <span className="user-name">{usr.name}</span> {/* Nombre */}
                          </div>
                          <div>
                            <span className="user-email">{usr.email}</span> {/* Email */}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    {/* Parte derecha: Teléfono y Rol */}
                    <Col xs={12} sm={8} md={8} lg={6} className="user-card-right">
                      <div className="phone-column">
                        <span className="user-phone">{usr.phone.e164}</span> {/* Teléfono */}
                      </div>
                      {user?.user?.role === "ADMIN" && (
                        <div>
                          <span className="user-role">{usr.role}</span> {/* Rol */}
                        </div>
                      )}
                    </Col>
                  </Row>
                </Card>
              </Skeleton>
              ))}
            </div>
          <Modal open={open} onCancel={handleCancel} footer={null} keyboard={true}>
            <Row className="modal-share-wrapper">
              <div className="modal-shared-img-container">
                <img src={sharedImage} alt="Share" className="modal-shared-img" />
              </div>
              <Col>
                <h3 className="modal-h3">{t("share_access")}</h3>
                <p>{t("manage_users")}</p>
              </Col>
            </Row>
            <label>{t("access_link")}</label>
            <Input style={{ marginBottom: "25px" }} id="searchInp" value={inviteLink} readOnly suffix={<CopyOutlined onClick={handleCopy} style={{ padding: "10px" }} />} />
            <label>{t("email")}</label>
            <div className="email-input-wrapper">
              <Input
                size="small"
                placeholder={t("email")}
                defaultValue={email}
                allowClear
                onBlur={(e: any) => {
                  const emailValue = e.target.value;
                  setEmail(emailValue);
                  if (validateEmail(emailValue)) {
                    setIsValidEmail(true);
                  } else {
                    setIsValidEmail(false);
                    message.error("Correo electrónico no válido");
                  }
                }}
              />
              <StyledButton size="sm" ml="10px" hasIcon disabled={disable} onClick={() => handleSend()}>
                {t("send")}
              </StyledButton>
            </div>
          </Modal>
        </Card>
    </Space>
  );
};
