/* eslint-disable */
import React, { FC, useEffect, useState } from "react";
import { useGoCardlessDropin, GoCardlessDropinOptions } from "@gocardless/react-dropin";
import { Button } from "antd";
import axios from "axios";
import API from "@doar/shared/methods/api";

// Display a button that opens the Dropin on click, starting a checkout flow for the specified Billing Request Flow.

const DropinButton: FC<{ options: GoCardlessDropinOptions }> = ({ options }) => {
  const { open } = useGoCardlessDropin(options);

  return (
    <Button type="primary" onClick={open} className="gocardlessBtn">
      Agregar SEPA
    </Button>
  );
};
interface IProps {
  customer: any;
  account: any;
  handleUpdateAccount: any;
}

const GoCardlessComponent: FC<IProps> = ({ customer, account, handleUpdateAccount }) => {
  const [flowID, setFlowID] = useState<string | null>(null);

  function handleExit(e: any) {
    console.log("EXIT", e);
  }
  async function handleSuccess(e: any) {
    console.log("SUCCESS", e);

    let accountUtil: any = JSON.parse(JSON.stringify(account));
    const data = {
      payment_type: "DIRECT_DEBIT",
      payment_due_days: 6,
      gocardless_client_id: e?.mandate_request?.links?.mandate,
    };
    accountUtil["payment_details"] = data;

    const resp = await API.put(`/customers/${customer.id}/accounts/${account.id}`, accountUtil);
    handleUpdateAccount({
      customer: customer.id,
      account: accountUtil,
    });
  }

  async function createFlow() {
    try {
      const url = process.env.NODE_ENV === "development" ? "https://europe-west3-bookline-dev.cloudfunctions.net/api/gocardless/create-br" : "https://europe-west3-bookline-pro.cloudfunctions.net/api/gocardless/create-br";
      let response = await axios.get(url);
      setFlowID(response?.data?.data);
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
    }
  }

  useEffect(() => {
    createFlow();
  }, []);

  // Only show the button once we have a Billing Request Flow ID
  return flowID === null ? (
    <div className="loader">Loading...</div> // It's recommended to handle loading state properly
  ) : (
    <DropinButton options={{ billingRequestFlowID: flowID, environment: process.env.NODE_ENV === "development" ? "sandbox" : "live", onExit: handleExit, onSuccess: handleSuccess }}></DropinButton>
  );
};

export default GoCardlessComponent;
