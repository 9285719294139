/* eslint-disable */
/* eslint-disable import/no-unresolved */
import { FC } from "react";
import SidebarCallsSearch from "src/components/apps/calls/sidebar-search";
import { useAppSelector } from "../../../../redux/hooks";
import { StyledBody, StyledFooter, StyledHeader, StyledSidebar } from "./style";

interface IProps {
    layout?: 1 | 2;
}

const Sidebar: FC<IProps> = ({ layout }) => {
    const { sidebar } = useAppSelector((state) => state.ui);
    return (
        <StyledSidebar className="sidebar" $layout={layout} $sidebar={sidebar}>
            <StyledBody>
                <SidebarCallsSearch />
            </StyledBody>
        </StyledSidebar>
    );
};

Sidebar.defaultProps = {
    layout: 1,
};

export default Sidebar;
