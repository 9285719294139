/* eslint-disable */
import { FC, useEffect, useState } from "react";
import { Col, Spinner } from "@doar/components";
import { RadialData } from "@doar/shared/data/dashboard-four";
import RadialPercentage from "../../../components/dashboard-four/radial-percentage";
import API from "@doar/shared/methods/api";
interface IProps {
  prefill?: string;
}

const RightRowOne: FC<IProps> = ({ prefill }) => {
  let restProps = {};
  const [time, setTime] = useState("2m30s");
  const [spinner, setSpinner] = useState(false);

  function timestampToISODateAtStartOfDay(timestamp: number) {
    const date = new Date(timestamp);
    date.setHours(0, 0, 0, 0);
    return date.toISOString();
  }
  function timestampToISODateAtEndOfDay(timestamp: number) {
    const date = new Date(timestamp);
    date.setHours(23, 59, 59, 0);
    return date.toISOString();
  }

  async function getCalls(offset: number, botID: string, dateFrom: number, dateTo: number) {
    const response: any = await API.get(`/calls?date_from=${timestampToISODateAtStartOfDay(dateFrom)}&date_to=${timestampToISODateAtEndOfDay(dateTo)}&bot_id=${botID}&offset=${offset}`);

    return response.data;
  }

  async function getAllCalls(botId: string, daysBefore: number) {
    const dateTo = +new Date();
    const dateFrom = +new Date() - daysBefore * 86400000;

    let response_data = await getCalls(0, botId, dateFrom, dateTo);

    let results = response_data.data;
    if (response_data.pagination.total > response_data.data.length) {
      const offset_increment = response_data.pagination.limit ?? response_data.data.length;
      let offset = offset_increment;
      let promises = [];
      while (offset < response_data.pagination.total) {
        promises.push(getCalls(offset, botId, dateFrom, dateTo));
        offset += offset_increment;
      }
      for (const promise of promises) {
        results.push(...(await promise).data);
      }
    }
    // console.log("ALL CALLS>>", results)
    calculateAvgDuration(results);
  }

  // Function to parse timestamp strings to Date objects
  function parseTimestamp(timestampStr: string): Date {
    return new Date(timestampStr);
  }

  function formatDuration(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60); // Round down to remove decimals

    if (minutes === 0) {
      return `${remainingSeconds}s`;
    } else if (remainingSeconds === 0) {
      return `${minutes}m`;
    } else {
      return `${minutes}m${remainingSeconds}s`;
    }
  }

  function calculateAvgDuration(data: any) {
    // Initialize variables to store total duration and count of calls
    let totalDuration = 0;
    let callCount = 0;

    // Iterate through the data
    for (const call of data) {
      const interactions = call.interactions;
      if (interactions.length > 0) {
        // Get the start and end timestamps of the call
        const startTime = parseTimestamp(interactions[0].timestamp);
        const endTime = parseTimestamp(interactions[interactions.length - 1].timestamp);

        // Calculate the duration of the call in milliseconds
        const callDuration = endTime.getTime() - startTime.getTime();

        // Add the call duration to the total duration
        totalDuration += callDuration;

        // Increment the call count
        callCount++;
      }
    }

    // Calculate the average duration in milliseconds
    const averageDuration = callCount > 0 ? totalDuration / callCount : 0;

    // Convert milliseconds to seconds for display
    const averageDurationSeconds = averageDuration / 1000;
    setTime(formatDuration(averageDurationSeconds));
    setSpinner(false);
  }

  useEffect(() => {
    if (prefill) {
      setSpinner(true);
      getAllCalls(prefill, 5);
    }
  }, [prefill]);

  const chart = {
    series: [100],
    options: {
      chart: {
        sparkline: {
          enabled: true,
        },
      },
      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "65%",
          },
          dataLabels: {
            showOn: "always",
            name: {
              offsetY: 20,
              show: true,
              color: "#8392a5",
              fontSize: "9px",
            },
            value: {
              offsetY: -10,
              color: "#001737",
              fontSize: "1.53125rem",
              show: true,
            },
          },
        },
      },
      labels: ["Atendidas"],
      responsive: [
        {
          breakpoint: "576px",
          options: {
            chart: {
              offsetX: -78,
            },
          },
        },
      ],
      colors: ["#65e0e0"],
    },
  };

  if (prefill) {
    return (
      <Col col={12} md={6} lg={12} {...restProps}>
        <RadialPercentage spinner={spinner} title="Tiempo promedio de llamada" desc="en los últimos 10 dias" min={time} chart={chart} sec={prefill} />
      </Col>
    );
  } else {
    return <div />;
  }
};

export default RightRowOne;
