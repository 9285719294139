/* eslint-disable */
import { FC, useEffect, useState } from "react";
import { Avatar, AvatarInitial } from "@doar/components";
import logo from "@doar/shared/images/logo/logo-ruedita.png";
import { useAppSelector } from "../../../../redux/hooks";
import { StyledWrap, StyledName } from "./style";

// import { Storage } from "@google-cloud/storage";

const DirectTitle: FC = () => {
  const { person } = useAppSelector((state) => state.callsUI);
  const { call } = useAppSelector((state) => state.callsUI);

  function timeConverter(ISO_timestamp: any) {
    if (ISO_timestamp) {
      const date = new Date(new Date(ISO_timestamp));
      // .setHours(new Date(ISO_timestamp).getHours() - 1));
      const a = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
      return a;
    }
    return "";
  }

  return (
    <StyledWrap>
      <Avatar size="sm">
        <img src={logo} alt={person?.name} />
      </Avatar>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <StyledName>Assistant: {person?.name} </StyledName>

          <StyledName>{timeConverter(call?.payload?.start)}</StyledName>
        </div>
        <StyledName>
          Cliente: {call?.payload?.source}
          <br />{" "}
        </StyledName>
      </div>
    </StyledWrap>
  );
};

export default DirectTitle;
