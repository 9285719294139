/* eslint-disable */
/* eslint-disable import/no-unresolved */
import { FC, useEffect, useState } from "react";
import "./styles.css";
import { useTranslation } from "react-i18next";
import { LANGUAGES } from "src/utils";
import i18n from "src/i18n";
import { Space, Card, Row } from "antd";
import "./styles.css";

interface IProps {
  layout?: 1 | 2 | 3;
}

const PreferencesTabComponent: FC<IProps> = ({ layout }) => {
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem("selectedLanguage") || "en");

  const onChangeLang = (e: { target: { value: any } }) => {
    const newSelectedLanguage = e.target.value;
    setSelectedLanguage(newSelectedLanguage);
    localStorage.setItem("selectedLanguage", newSelectedLanguage);
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);

  return (
    <Space direction="vertical" size={16} className="team-tab-wrapper">
      <Card title={t("preferences")} className="team-tab-wrapper-card">
            <Row align="middle" style={{padding: '24px'}}>
              <h3 className="preferences-select-title">{t("language")}</h3>
              <div >
                <select defaultValue={selectedLanguage} onChange={onChangeLang}>
                  {LANGUAGES.map(({ code, label }) => (
                    <option key={code} value={code}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
            </Row>
      </Card>
    </Space>
  );
};
PreferencesTabComponent.defaultProps = {
  layout: 3,
};

export default PreferencesTabComponent;
