/* eslint-disable */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import uuid from "react-uuid";

export function idGenerator(param: string) {
    const date = Date.now().toString().slice(-4);
    const uuidUtil = uuid().slice(-4);
    const uniqueID = `${param}-${date}-${uuidUtil}`;
    return uniqueID;
}
