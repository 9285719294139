/* eslint-disable */
/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable prefer-template */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC, useContext, useEffect, useState } from "react";
import Label from "src/components/apps/contacts/label-2";
import { CustomerContext } from "src/config/userContext";
import API from "@doar/shared/methods/api";
import rocket from "@doar/shared/images/rocket.svg";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Drawer, Row, Select, Skeleton, Space } from "antd";
import GoCardlessComponent from "src/components/gocardless";
import "./styles.css";
import axios from "axios";
import { t } from "i18next";
import { StyledButton } from "src/containers/dashboard-four/welcome-area/style";

interface IProps {}

export const BillingTabComponent: FC<IProps> = () => {
  const { customer } = useContext(CustomerContext);
  const [accountSelected, setAccountSelected] = useState<any>();
  const [accounts, setAccounts] = useState<any>([]);
  const [mandateDetails, setMandateDetails] = useState<any>({
    bank_name: "",
    account_holder_name: "",
    account_number_ending: "",
    currency: "",
    mandate_status: "",
  });
  const [open, setOpen] = useState(false);
  const [showUpdatePayment, setShowUpdatePayment] = useState(false);
  const [accName, setAccName] = useState("");
  const [accCif, setAccCif] = useState("");
  const [accPartnerName, setAccPartnerName] = useState("");
  const [accNifPartner, setAccNifPartner] = useState("");
  const [accEmail, setAccEmail] = useState("");
  const [accAddress, setAccAddress] = useState("");
  const [accCity, setAccCity] = useState("");
  const [accCp, setAccCp] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);
  const [fieldMissing, setFieldMissing] = useState(false);
  const [skeleton, setSkeleton] = useState(true)

  const handleChoice = (e: any) => {
    const acc = accounts.find((acc: any) => e === acc?.id);
    setMandateDetails({
      bank_name: "",
      account_holder_name: "",
      account_number_ending: "",
      currency: "",
      mandate_status: "",
    });
    setAccountSelected(acc);
  };

  function handleUpdateAccount(data: any) {
    console.log("update account", data);
    const accountsUtil = accounts.map((acc: any) => {
      if (acc.id === data?.account?.id) {
        return data?.account;
      } else return acc;
    });
    setAccounts(accountsUtil);
    setAccountSelected(data.account);
  }

  function splitMail(mails: string) {
    return mails.split(",").map((mail: string) => {
      return mail.trim();
    });
  }
  function isValidEmail(email: string): boolean {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  function handleEmail(e: React.ChangeEvent<HTMLInputElement>) {
    if (!isValidEmail(e.target.value)) {
      setErrorEmail(true);
    } else {
      setErrorEmail(false);
    }
  }

  function checkMandatoryFields() {
    if (accCif !== "" && accName !== "" && accAddress !== "" && accPartnerName !== "" && accNifPartner !== "" && accEmail !== "") {
      setFieldMissing(false);
      return true;
    } else {
      setFieldMissing(true);
      return false;
    }
  }

  const showDrawer = () => {
    setOpen(true);
  };

  function handleAddAcc() {
    if (checkMandatoryFields() && isValidEmail(accEmail)) {
      setOpen(false);

      const acc = {
        id: accCif,
        legal_id: accCif,
        legal_name: accName ?? "",
        address: accAddress ? { full_address: accAddress, city: accCity ?? "", postal_code: accCp ?? "" } : "",
        partner_full_name: accPartnerName ?? "",
        partner_legal_id: accNifPartner ?? "",
        accounting_email: accEmail ? splitMail(accEmail) : "",
        payment_frequency: "monthly",
        custom_invoice_reference: "",
        default_tax_rate: 21,
      };

      API.post(`/customers/${customer?.id}/accounts`, acc)
        .then((res) => {
          setAccCif("");
          setAccName("");
          setAccAddress("");
          setAccCity("");
          setAccCp("");
          setAccPartnerName("");
          setAccNifPartner("");
          setAccEmail("");
          setAccounts((prevAccounts: any) => [...prevAccounts, res.data]);
          setAccountSelected(res.data);
        })
        .catch((err) => console.log(err));
    }
    return false;
  }

  function handleCancelAddAcc() {
    setErrorEmail(false);
    setFieldMissing(false);
    setOpen(false);
    setAccCif("");
    setAccName("");
    setAccAddress("");
    setAccCity("");
    setAccCp("");
    setAccPartnerName("");
    setAccNifPartner("");
    setAccEmail("");
  }

  useEffect(() => {
    if (customer?.id) {
      API.get(`/customers/${customer.id}/accounts`)
        .then((res) => {
          if (res.data.data.length > 0) {
            setAccountSelected(res.data.data[0]);
            setAccounts(res.data.data);
          }
          setTimeout(() => {
            setSkeleton(false);
          }, 1000);
        })
        .catch((err) => console.log(err));
    }
  }, [customer]);

  async function getMandateData(mandateId: string) {
    try {
      const url = process.env.NODE_ENV === "development" ? "https://europe-west3-bookline-dev.cloudfunctions.net/api/gocardless/mandate/" : "https://europe-west3-bookline-pro.cloudfunctions.net/api/gocardless/mandate/";
      let response = await axios.get(url + mandateId);
      setMandateDetails(response?.data?.data);
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
    }
  }

  useEffect(() => {
    if (accountSelected?.payment_details?.gocardless_client_id) getMandateData(accountSelected?.payment_details?.gocardless_client_id);
  }, [accountSelected]);

  const paymentFields = [
    { label: "Tipo de pago", value: "Debito directo" }, // Valor estático
    { label: "Banco", value: mandateDetails?.bank_name ?? t('no_specify')},
    { label: "Nombre", value: mandateDetails?.account_holder_name ?? t('no_specify')},
    { label: "IBAN", value: `**** **** **** ** ********${mandateDetails?.account_number_ending}` ?? t('no_specify')},
    { label: "Moneda", value: mandateDetails?.currency ?? t('no_specify')},
  ];

  const businessInfo = {
    companyDetails: [
      { label: "Nombre de la empresa", value: accountSelected?.legal_name ?? t('no_specify')},
      { label: "CIF", value: accountSelected?.legal_id ?? t('no_specify')},
      { label: "Socio", value: accountSelected?.partner_full_name ?? t('no_specify')},
    ],
    partnerDetails: [
      { label: "NIF Socio", value: accountSelected?.partner_legal_id ?? t('no_specify')},
      { label: "Correo electrónico", value: accountSelected?.accounting_email ?? t('no_specify')},
    ],
    addressDetails: [
      { label: "Dirección", value: accountSelected?.address?.full_address ?? t('no_specify')},
      { label: "Ciudad", value: accountSelected?.address?.city ?? t('no_specify')},
      { label: "CP", value: accountSelected?.address?.postal_code ?? t('no_specify')},
    ],
  };

  const colSize = 12 / paymentFields.length;

  return (
    <div>
      <Card
        title={t("billing")}
        className="team-tab-wrapper-card"
        extra={
          <StyledButton type="primary" onClick={showDrawer} icon={<PlusOutlined />}>
            {t("create_account")}
          </StyledButton>
        }
      >
        <div style={{padding: '24px'}}>
          <Row className="preferences-table-header">
            <h3 className="preferences-select-title">{t("account")}</h3>
            <div className="billing-select-wrapper">
              <Select onChange={handleChoice} value={accountSelected?.id} style={{ width: "100%" }}>
                {accounts?.map((account: any) => {
                  return (
                    <option key={account.id} value={account.id}>
                      {account.legal_name}
                    </option>
                  );
                })}
              </Select>
            </div>
          </Row>
          <div>
            <div style={{padding: '24px'}}>
              {accountSelected && (
                <>
                  {/* "-------------------------- ACCOUNT INFORMATION TAB --------------------------" */}

                  <Skeleton active loading={skeleton} className="section-wrapper" style={{padding: '24px'}}>
                    <div className="section-wrapper">
                      <div>
                        <div className="billing-table-header">{t('legal_information')}</div>
                      </div>
                      <Row className="section-item">
                        {businessInfo.companyDetails.map((field, index) => (
                          <Col key={index} xs={12} sm={12} lg={8}>
                            <Label>{field.label}</Label>
                            <span>{field.value}</span>
                          </Col>
                        ))}
                      </Row>
                      {/* <div>Domicilio fiscal</div> */}
                      <Row className="section-item">
                        {businessInfo.addressDetails.map((field, index) => (
                          <Col key={index} xs={12} sm={12} lg={8}>
                            <Label>{field.label}</Label>
                            <div>{field.value}</div>
                          </Col>
                        ))}
                      </Row>
                      <Row className="section-item">
                        {businessInfo.partnerDetails.map((field, index) => (
                          <Col key={index} xs={12} sm={12} lg={8}>
                            <Label>{field.label}</Label>
                            <div>{field.value}</div>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </Skeleton>
                  <Skeleton  active loading={skeleton} className="section-wrapper" style={{padding: '24px'}}>
                    <div className="section-wrapper">
                      <div className="billing-table-header">Método de pago</div>
                      {accountSelected?.payment_details?.payment_type === "DIRECT_DEBIT" && accountSelected?.payment_details?.gocardless_client_id ? (
                        <div>
                          <Row className="section-item-wrapper">
                            {paymentFields.map((field, index) => (
                              <Col className="section-item" key={index} xs={12} sm={colSize} md={colSize} lg={colSize} xl={colSize}>
                                <Label>{field.label}</Label>
                                <div>{field.value}</div>
                              </Col>
                            ))}
                          </Row>
                          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
                            {!showUpdatePayment ? (
                              <Button type="primary" onClick={() => setShowUpdatePayment(true)}>
                                Actualizar método de pago
                              </Button>
                            ) : (
                              <GoCardlessComponent customer={customer} account={accountSelected} handleUpdateAccount={handleUpdateAccount} />
                            )}
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="configPagoDiv">
                            <div>Configure su método de pago configurando el Débito Directo a través de</div>
                            <img src={rocket} alt="rocket img" />
                          </div>
                          <div className="gocardlessDiv">
                            <GoCardlessComponent customer={customer} account={accountSelected} handleUpdateAccount={handleUpdateAccount} />
                          </div>
                        </>
                      )}
                    </div>
                  </Skeleton>
                </>
              )}
              {accountSelected && <div />}
            </div>
          </div>
        </div>
      </Card>
      <>
        {/* Drawer de add account */}
        <Drawer title={t("create_account")} width={720} onClose={handleCancelAddAcc} open={open}>
          <div>
            {fieldMissing && <div style={{ color: "red", marginLeft: "15px" }}>Todos los campos son obligatorios.</div>}

            <div>
              <Col>
                <input className={`inputLegal `} placeholder={t("company_name")} value={accName} onChange={(e) => setAccName(e.target.value)} />
              </Col>
              <Col>
                <input className={`inputLegal`} placeholder="CIF" value={accCif} onChange={(e) => setAccCif(e.target.value)} />
              </Col>
            </div>
            <div>
              <Col>
                <input className={`inputLegal`} placeholder={t("partner_complete_name")} value={accPartnerName} onChange={(e) => setAccPartnerName(e.target.value)} />
              </Col>
              <Col>
                <input className={`inputLegal`} placeholder={t("partner_identification_number")} value={accNifPartner} onChange={(e) => setAccNifPartner(e.target.value)} />
              </Col>
            </div>
            <div>
              <Col>
                <input className={`inputLegal ${errorEmail ? "errorEmail" : ""} `} style={{ maxWidth: "100%" }} placeholder='Email' value={accEmail} onChange={(e) => setAccEmail(e.target.value)} onBlur={handleEmail} />
                {errorEmail && <div style={{ color: "red", marginLeft: "15px" }}>{t('not_valid_email')}</div>}
              </Col>
            </div>

            <h5 style={{ marginTop: "50px", marginLeft: "10px" }}>Domicilio fiscal</h5>

            <div>
              <Col className="gutter-row">
                <input className={`inputLegal`} placeholder={"address"} value={accAddress} onChange={(e) => setAccAddress(e.target.value)} />
              </Col>
            </div>
            <div>
              <Col className="gutter-row">
                <input className={`inputLegal`} placeholder={t("city")} value={accCity} onChange={(e) => setAccCity(e.target.value)} />
              </Col>
              <Col className="gutter-row">
                <input className={`inputLegal`} placeholder={t("postal_code")} value={accCp} onChange={(e) => setAccCp(e.target.value)} />
              </Col>
            </div>
          </div>
          <Space className="drawer-buttons">
            <Button onClick={handleCancelAddAcc}>{t("cancel")}</Button>
            <Button onClick={handleAddAcc} type="primary">
              {t("create_account")}
            </Button>
          </Space>
        </Drawer>
      </>
    </div>
  );
};
