/* eslint-disable */
import styled from "@doar/shared/styled";

export const StyledWrap = styled.div`
    width: 100% !important;
    display: flex;
    align-items: center;
    padding: 10px;
`;

export const StyledName = styled.h6`
    margin-left: 10px;
    margin-bottom: 3px;
`;
