/* eslint-disable */
/* eslint-disable import/no-unresolved */
import API from "@doar/shared/methods/api";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import SingleCall from "src/containers/apps/calls/single-call";
import { useAppDispatch } from "src/redux/hooks";
import { setCall, togglePerson } from "src/redux/slices/calls-ui";
import ContentBody from "../../layouts/layout-02/content-body";
import ContentHeader from "../../layouts/layout-02/content-header";

import SEO from "../../components/seo";
import CallsMain from "../../containers/apps/calls/main";
import CallsSidebar from "../../containers/apps/calls/sidebar";
import useSidebarMargin from "src/layouts/useSidebarMargin";

const Calls: FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const marginLeft = useSidebarMargin();

  useEffect(() => {
    if (id) {
      dispatch(setCall({}));
      dispatch(togglePerson({ person: { name: "", status: "offline" } }));

      API.get(`/calls/${id}`)
        .then((res) => {
          dispatch(setCall(res.data));
          dispatch(togglePerson({ person: { name: res.data?.origin_bot_id ? res.data?.origin_bot_id : "", status: "offline" } }));
        })
        .catch((err) => console.log(err));
    }
  }, [id]);
  return (
    <div style={{ marginLeft }}>
      <ContentHeader></ContentHeader>

      <SEO />
      <div style={{ height: "100vh" }}>
        <ContentBody container p={["0px", "0px"]}>
          {!id && <CallsSidebar layout={2} />}
          {!id && <CallsMain layout={2} />}
          {id && <SingleCall />}
        </ContentBody>
      </div>
    </div>
  );
};

export default Calls;
