/* eslint-disable */
import { FC, useEffect } from "react";
import ScrollBar from "../../../components/scrollbar";
import ChatCallGroup from "../../../components/apps/calls/chat-group";
import DirectTitle from "../../../components/apps/calls/direct-title";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { toggleSidebar } from "../../../redux/slices/chat-ui";

import { StyledMain, StyledHeader, StyledBody, StyledBodyInner } from "./main/style";

interface IProps {
  layout?: 1 | 2 | 3;
}

const SingleCall: FC<IProps> = ({ layout }) => {
  const dispatch = useAppDispatch();
  const { chatType, rightSidebar } = useAppSelector((state) => state.callsUI);
  const { sidebar } = useAppSelector((state) => state.ui);

  useEffect(() => {
    if (window.matchMedia("(max-width: 991px)").matches) {
      dispatch(toggleSidebar());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <StyledHeader>{chatType === "direct" && <DirectTitle />}</StyledHeader>
      <StyledBody $showSidebar={rightSidebar}>
        <ScrollBar>
          <StyledBodyInner>
            <ChatCallGroup />
          </StyledBodyInner>
        </ScrollBar>
      </StyledBody>
    </div>
  );
};

export default SingleCall;
