/* eslint-disable */
/* eslint-disable import/no-unresolved */
import { FC } from "react";
import LocalesContainer from "src/containers/locales";
import SEO from "../components/seo";
import Content from "../layouts/layout-02/content";
import ContentBody from "../layouts/layout-02/content-body";
import useSidebarMargin from "src/layouts/useSidebarMargin";
import ContentHeader from "src/layouts/layout-02/content-header";

const Bots: FC = () => {
  const marginLeft = useSidebarMargin();

  return (
    <div style={{ marginLeft}}>
      <ContentHeader></ContentHeader>
      <SEO />
        <ContentBody container={false}>
          <LocalesContainer />
        </ContentBody>
    </div>
  );
};

export default Bots;
