/* eslint-disable */
/* eslint-disable import/no-unresolved */
import { Avatar, AvatarInitial, Badge, Button, MediaBody, Modal, ModalFooter, ModalHeader, ModalTitle, Text } from "@doar/components";
import API from "@doar/shared/methods/api";
import { FC, MouseEvent, useContext, useState } from "react";
import { Trash2 } from "react-feather";
import { UserContext } from "src/config/userContext";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { setCallsArr, togglePerson } from "../../../../redux/slices/calls-ui";
import { toggleSidebar } from "../../../../redux/slices/ui";
import { StyledChatItem } from "./style";
import "./styles.css";

interface IProps {
  id: string;
  status: "online" | "offline";
  userName?: string;
  userPhone?: string;
  userLogo?: string;
  image?: string;
  bg?: string;
  tag?: string;
  name: string;

  lastMsgDay: string;
  lastMsgTime: string;
  unseenMsg?: number;
  activeId: string;

  onClick: (e: MouseEvent<HTMLDivElement>) => void;
}

const Item: FC<IProps> = ({ id, status, userName, userPhone, userLogo, tag, bg, image, name, lastMsgDay, lastMsgTime, unseenMsg, activeId, onClick }) => {
  const dispatch = useAppDispatch();
  const { chatType } = useAppSelector((state) => state.callsUI);
  const { callsArr } = useAppSelector((state) => state.callsUI);

  const { user } = useContext(UserContext);
  const [show, setShow] = useState(false);

  function handleClose() {
    setShow(!show);
  }

  function updateCalls() {

    dispatch(setCallsArr(callsArr.payload.filter((call: any) => call.id !== id)));
  }

  function deleteConfirmed() {
    API.delete(`/calls/${id}`)
      .then((res) => {
        updateCalls();
      })
      .catch((err) => {
        console.log(err);
      });
    setShow(false);
  }

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    onClick(e);
    dispatch(toggleSidebar({ isOpen: undefined }));
    dispatch(togglePerson({ person: { image, bg, status, name } }));
  };
  function deleteCall(call: any) {
  }

  function getInitials() {
    let nameUtilArr = name.split("-");
    let nameUtil = "";
    nameUtilArr.forEach((word: string) => {
      nameUtil = nameUtil + word.substring(0, 1);
    });
    if (nameUtil.length > 2) {
      nameUtil = nameUtil.slice(0, 2);
    }

    return nameUtil;
  }
  return (
    <StyledChatItem id={id} $active={activeId === id && chatType === "direct"} onClick={handleClick}>
      <Avatar size="sm" status={status}>
        {image && <img src={image} alt={name} />}
        {!image && <AvatarInitial bg={bg}>{getInitials()}</AvatarInitial>}
      </Avatar>
      <div className="itemHead">
        <h6 style={{ fontFamily: "Lato ,sans-serif", lineHeight: 1.25, color: " #001737", fontWeight: 500, margin: "0px" }}>{userName || userPhone}</h6>
        <Text as="small" display="block" color="text4">
          {name}
        </Text>
      </div>

      <MediaBody>
        <Text as="small" display="block" color="text4" textAlign="right">
          {lastMsgDay}
        </Text>
        <Text as="small" display="block" color="text4" textAlign="right">
          {lastMsgTime}
        </Text>
      </MediaBody>
      {tag === "Completed" && (
        <Badge color="success" shape="square">
          {tag}
        </Badge>
      )}
      {tag === "Not Booked" && (
        <Badge color="danger" shape="square">
          {tag}
        </Badge>
      )}
      {user?.user?.role === "ADMIN" && (
        <button className="deleteButton" style={{ marginLeft: "8px" }} type="button" onClick={() => setShow(true)}>
          <Trash2 />
        </button>
      )}
      <Modal show={show} size="sm" onClose={handleClose}>
        <ModalHeader>
          <ModalTitle>Confirm Delete </ModalTitle>
        </ModalHeader>

        <ModalFooter>
          <Button color="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button color="primary" onClick={deleteConfirmed}>
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </StyledChatItem>
  );
};

Item.defaultProps = {
  bg: "dark",
};

export default Item;
