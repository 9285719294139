/* eslint-disable */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-lonely-if */
/* eslint-disable no-empty */
import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";
import { useTranslation } from "react-i18next";

import { FC, useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { CustomerContext } from "src/config/userContext";

/* eslint-disable import/no-unresolved */
import { Button, Checkbox } from "@doar/components";

interface IProps {
  preselected?: any;
  prefill?: any;
  onChangeBots?: any;
}

export const BotsFilfter: FC<IProps> = ({ prefill, onChangeBots, preselected }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeClass, setActiveClass] = useState("");
  const [checkList, setCheckList] = useState<boolean[]>([]);

  function handleCheckList(index: number) {
    let checkListUtil = [...checkList];
    const count2 = checkListUtil.filter(Boolean).length;

    if (checkListUtil[index] === false && count2 === 10) {
    } else {
      checkListUtil[index] = !checkListUtil[index];
      setCheckList(checkListUtil);
    }
  }

  function handleClear() {
    let checkListUtil: boolean[] = [];
    checkList.forEach((bot: any) => {
      checkListUtil.push(false);
    });
    setCheckList(checkListUtil);
  }
  function handleSearch() {
    let botsSelected: any[] = [];
    for (let i = 0; i < prefill.length; i += 1) {
      if (checkList[i]) {
        botsSelected.push(prefill[i].id);
      }
    }

    if (botsSelected.length > 0) {
      setActiveClass("activeFilter");
    } else {
      setActiveClass("");
    }
    setShowDropdown(false);

    onChangeBots(botsSelected);
  }

  useEffect(() => {
    let checkListUtil: boolean[] = [];
    if (prefill) {
      prefill.forEach((bot: any) => {
        checkListUtil.push(false);
      });
      setCheckList(checkListUtil);
    }
  }, [prefill]);

  function handleClickOutside(e: any) {
    let parent = document.getElementsByClassName("dropdownDivFilter")[0];
    let filterButton = document.getElementById("filterButton");

    if (parent?.contains(e.target) || filterButton?.contains(e.target)) {
    } else {
      setShowDropdown(false);
    }
  }
  useEffect(() => {
    let checkListUtil: boolean[] = [];
    let count = 0;
    if (preselected && prefill) {
      prefill.forEach((bot: any) => {
        count += 1;
        if (count < 11) {
          checkListUtil.push(true);
        } else {
          checkListUtil.push(false);
        }
      });
      setCheckList(checkListUtil);
      setActiveClass("activeFilter");
    }
  }, [preselected, prefill]);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div>
      <div id="filterButton" className={`barButton ${activeClass} filterButton`} role="button" tabIndex={0} onClick={(e: any) => setShowDropdown(!showDropdown)}>
        Filtrar por asistente
      </div>

      {showDropdown && (
        <div className="dropdownDivFilter">
          <div className="dropdownList">
            {prefill?.map((bot: any, index: number) => (
              <div role="button" tabIndex={index} className={`checkListStyle checkList${checkList[index]?.toString()}`} onClick={() => handleCheckList(index)} key={bot.id}>
                <input type="checkbox" className="checkbox" defaultChecked={checkList[index]} /> <span className="span">{bot.name}</span>
              </div>
            ))}
          </div>
          <div className="botFilterButtons">
            <Button color="primary" onClick={handleSearch} margin={"0 10px 0 0"}>
              Filtrar
            </Button>
            <Button color="light" onClick={handleClear}>
              Limpiar
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
