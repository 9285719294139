/* eslint-disable */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-lonely-if */
/* eslint-disable no-bitwise */
/* eslint-disable no-empty */
/* eslint-disable operator-assignment */
import { FC, useEffect, useState } from "react";
import { useAppSelector } from "src/redux/hooks";
import Item from "./item";
import { StyledGroup, StyledGroupEvent, StyledGroupReply } from "./style";

interface IProps {
  callParam?: any;
}
const ChatGroup: FC<IProps> = ({ callParam }) => {
  const [messages, setMessages] = useState<any[]>([]);
  const { call } = useAppSelector((state) => state.callsUI);
  const assistantName = "Assistant";

  function clean(text: string) {
    let textUtil = text;

    while (textUtil.indexOf("<") !== -1) {
      textUtil = textUtil.slice(0, textUtil.indexOf("<")) + textUtil.slice(textUtil.indexOf(">") + 1);
    }
    return textUtil;
  }

  function timeConverter(ISO_timestamp: any) {
    let date: Date = new Date(ISO_timestamp);

    let diff = date.getTime() - new Date(call.payload.start).getTime();
    diff = diff / 1000;

    const a = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    if (diff === 0) {
      return "";
    }
    return `${~~diff} s`;
  }

  let count = 0;
  function keyProvider() {
    count += 1;

    return count;
  }

  useEffect(() => {
    let messagesUtil: any[] = [];
    call?.payload?.interactions?.forEach((interaction: any) => {
      if (interaction.input.reason === "timeout") {
        messagesUtil.push({
          reply: true,
          event: false,
          bot: false,
          name: `Cliente`,
          // name: `User ${call.payload.source as string}`,
          time: timeConverter(interaction.timestamp),
          content: "-- SIN RESPUESTA --",
        });
      } else if (interaction.input.call_status === "completed") {
        messagesUtil.push({
          content: "La llamada ha terminado",
          name: `Cliente`,
          event: true,
          // name: `User ${call.payload.source as string}`,
          time: timeConverter(interaction.timestamp),
        });
      } else if (interaction.input.call_status === "trying") {
        // messagesUtil.push({
        //     content: "* El usuario ha iniciado la llamada.",
        //     name: `User ${call.payload.source as string}`,
        //     time: timeConverter(interaction.timestamp),
        //     reply: true,
        //     bot: false,
        // });
      } else if (interaction.input.user_input !== null && interaction.input.user_input) {
        messagesUtil.push({
          content: interaction.input.user_input,
          name: `Cliente`,
          event: false,
          // name: `User ${call.payload.source as string}`,
          time: timeConverter(interaction.timestamp),
          reply: true,
          bot: false,
        });
      }

      interaction.output.forEach((output: any) => {
        if (output.action_type === "dial") {
          messagesUtil.push({
            content: `llamada transferida a  ${output.action_data as string}`,
            time: timeConverter(interaction.timestamp),
            event: true,
          });
        } else {
          if (output.action_data != null) {
            if (Array.isArray(output.action_data)) {
              if (output.action_data.length > 0) {
                output.action_data.forEach((actionData: any) => {
                  if (actionData.includes("<audio")) {
                    let actionFilter = actionData.split('"');
                    let srcUtil = "";
                    actionFilter.forEach((el: string) => {
                      if (el.includes("https")) {
                        srcUtil = el;
                      }
                    });

                    messagesUtil.push({
                      content: srcUtil,
                      name: assistantName,
                      reply: false,

                      bot: true,
                      time: timeConverter(interaction.timestamp),
                    });
                  } else {
                    messagesUtil.push({
                      content: clean(actionData),
                      name: assistantName,
                      reply: false,
                      bot: true,
                      time: timeConverter(interaction.timestamp),
                    });
                  }
                });
              }
            } else {
              if (output.action_data.includes("jambonz")) {
                // jambonz
              } else {
                messagesUtil.push({
                  content: clean(output.action_data),
                  name: assistantName,
                  time: timeConverter(interaction.timestamp),

                  reply: false,
                  bot: true,
                });
              }
            }
          }
        }
      });
    });

    setMessages(messagesUtil);
    messagesUtil = [];
  }, [call]);

  return (
    <>
      {messages.map((msg) => {
        return (
          <div key={keyProvider()}>
            {msg.bot && !msg.event && (
              <StyledGroup>
                <Item bot name={msg.name} event={msg.event} time={msg.time} content={msg.content} />
              </StyledGroup>
            )}
            {!msg.bot && !msg.event && (
              <StyledGroupReply>
                <Item reply name={msg.name} event={msg.event} time={msg.time} content={msg.content} />
              </StyledGroupReply>
            )}
            {msg.event && (
              <StyledGroupEvent>
                <Item reply name={msg.name} event={msg.event} time={msg.time} content={msg.content} />
              </StyledGroupEvent>
            )}
          </div>
        );
      })}
    </>
  );
};

export default ChatGroup;
