/* eslint-disable */
/* eslint-disable import/no-unresolved */
import { FC, useContext, useState } from "react";
import { Plus, Save, Share2 } from "react-feather";
import { CustomerContext } from "src/config/userContext";

import Breadcrumb from "../../../components/breadcrumb";
import ModalCreateTicket from "../../../components/dashboard-four/modal-create-ticket";
import ModalSave from "../../../components/dashboard-four/modal-save";
import ModalShare from "../../../components/dashboard-four/modal-share";
import { StyledButton, StyledWelcomeArea, StyledWelcomeLeft, StyledWelcomeRight } from "./style";

const WelcomeArea: FC = () => {
  const { customer } = useContext(CustomerContext);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showTicketModal, setShowTicketModal] = useState(false);
  const handleSaveModal = () => {
    setShowSaveModal((prev) => !prev);
  };
  const handleShareModal = () => {
    setShowShareModal((prev) => !prev);
  };
  const handleTicketModal = () => {
    // methodNotDefined();
    setShowTicketModal((prev) => !prev);
  };
  let customerName = "Default";
  if (customer) {
    customerName = customer?.name;
  }
  return (
    <div id="page-wrapper" >
      <StyledWelcomeArea>
        <StyledWelcomeLeft>
          <Breadcrumb prev={[{ text: "Dashboard", link: "/" }]} title="Llamadas" wcText={`${customerName}`} />
        </StyledWelcomeLeft>
        <StyledWelcomeRight>
          <StyledButton size="sm" color="white" hasIcon onClick={handleSaveModal}>
            <Save />
            Save
          </StyledButton>
          <StyledButton size="sm" color="white" hasIcon ml="10px" onClick={handleShareModal}>
            <Share2 />
            Share
          </StyledButton>
          <StyledButton size="sm" ml="10px" hasIcon onClick={handleTicketModal}>
            <Plus />
            Add New Ticket
          </StyledButton>
        </StyledWelcomeRight>
      </StyledWelcomeArea>
      <ModalSave show={showSaveModal} onClose={handleSaveModal} />
      <ModalShare show={showShareModal} onClose={handleShareModal} />
      <ModalCreateTicket show={showTicketModal} onClose={handleTicketModal} />
    </div>
  );
};

export default WelcomeArea;
