/* eslint-disable */
import "./style.css";
import { FC, useContext, useEffect, useState } from "react";
import { CustomerContext, ShowSidebarContext } from "src/config/userContext";

import Breadcrumb from "../../components/breadcrumb";
import { StyledWelcomeArea, StyledWelcomeLeft, StyledWelcomeRight, StyledButton } from "../dashboard-four/welcome-area/style";
import WhatsAppWizardContainer from "../wizard-whatsapp-channel";
import { Button, Modal, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { auth, collection, dbWisecrack, firestore, orderBy, query } from "src/config/firebase";
import { doc, getDoc, getDocs, setDoc, where } from "firebase/firestore";
// import { Col, Input, Row } from "@doar/components";
// import whatsLogo from "../../../../shared/images/whastapp-logo.png";
// import { Trash, Trash2 } from "react-feather";
import { useNavigate, useSearchParams } from "react-router-dom";
import API from "@doar/shared/methods/api";
import FUNAPI from "@doar/shared/methods/webfun-api";
import { idGenerator } from "src/containers/integrations/id-generator";
import { useToasts } from "react-toast-notifications";
import { ConnectButton } from "360dialog-connect-button";
import axios from "axios";
import { fontSize, width } from "@doar/shared";
import whatsImage from "../channels/static/whats-chat.png";
import { Spinner } from "@doar/components";

const ChannelsContainer: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { customer, customerUpdate } = useContext(CustomerContext);
  const [whatsModalOpen, setWhatsModalOpen] = useState(false);
  // const [newVenue, setNewVenue] = useState<any>(null);
  const [channelsConfigs, setChannelsConfigs] = useState<any[]>([]);
  const [searchParams] = useSearchParams();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(true);
  const { showSidebar } = useContext(ShowSidebarContext); 

  const defaultInteg = {
    id: "",
    name: "",
    config: {
      integration_type: "WHATSAPP_360D",
      sender_phone: { e164: "+34000000000" },
      credentials: {
        endpoint_base_path: "https://waba.360dialog.io",
        api_key: "",
        client_id: "",
        waba_account_id: "",
        channel_id: "",
      },
      reserved_tags: [],
      nlg_defaults: {},
    },
  };

  function installChannel(data: any, callbackObject: any, wisecrackChannel: any) {
    API.get("/customers/" + data.owner_id).then(async (resCust) => {
      const cust = resCust.data;
      let index = 0;
      for (const channel of callbackObject.channels) {
        if (!checkChannelInstalled(channel, cust)) {
          await install(index, callbackObject, channel, data, false, null);
        } else {
          addToast("This channel was installed before, updating integration...", {
            appearance: "warning",
            autoDismiss: true,
            autoDismissTimeout: 3000,
          });

          console.log("cust", cust);

          const integration = cust.integrations.find((int: any) => int?.config?.credentials?.channel_id == channel);

          await install(index, callbackObject, channel, data, true, integration);
        }
      }
      if (callbackObject?.channels?.length > 1) {
        navigate("/channels");
      } else {
        navigate("/channels/" + wisecrackChannel);
      }
    });
  }
  async function install(index: number, callbackObject: any, channel: any, data: any, update = false, integration: any) {
    const config = { channel_id: channel };
    const res = await API.post(`/onboarding/whatsapp_360d`, config);
    index++;
    handleRegisterNumber(res.data, data.owner_id);
    handleWebHook(res.data);
    let newIdUtil = "";
    if (update) {
      newIdUtil = integration.id;
    } else {
      newIdUtil = idGenerator("360D");
    }
    handleNewConnection(res.data, callbackObject, channel, data.owner_id, newIdUtil, update, integration);

    let dataUtil = { ...data };
    dataUtil.request.status = "online";
    dataUtil["integration_id"] = newIdUtil;
    dataUtil["phone"] = res.data.sender;
    dataUtil["channel_id"] = channel;

    await updateDB(dataUtil);
  }
  // http://localhost:3000/channels?client=oV922HIVCL&channels=[8PtYIoCH]&state=56ea413c-6e16-42c4-91b4-25393fe838bb

  const handleCallback = (callbackObject: any) => {
    console.log("CALLBACK OBJ", callbackObject);

    /* The callback function returns the client ID as well as all channel IDs, for which you're enabled to fetch the API key via the Partner API */

    const wisecrackChannel = searchParams.get("state");

    if (wisecrackChannel) {
      // Referencia al documento específico
      const docRef = doc(dbWisecrack, "channels", wisecrackChannel);

      // Obtener el documento
      getDoc(docRef)
        .then(async (docSnap) => {
          if (docSnap.exists()) {
            const data = docSnap.data();
            // console.log("Datos del documento:", data);
            // console.log("CALLBACK OBJ:", callbackObject);

            if (callbackObject.channels.length > 1) {
              if (window.confirm("You have selected more than one channel, do you want to create a connection for each one?")) {
                console.log("mas de un channel >>> TODO");
              }
            } else {
              const funapiiResp = await FUNAPI.get("/360-dialog/shared-numbers/" + callbackObject.client);
              // console.log("FUNAPI RESPONSE", funapiiResp.data.data);

              //EJEMPLO DE DATA
              // data = {
              //   channel_type: "WHATSAPP",
              //   id: "9d4f58bd-5ab1-42cb-bece-f78598b9fbdc",
              //   request: {
              //     phone: "34661062464",
              //     whatsapp_type: "personal_use",
              //     reset_account: "reset",
              //     status: "ready_for_onboarding",
              //   },
              //   owner_id: "bookline-test",
              // };

              // EJEMPLO DE CALLBACK OBJ
              // callbackObj = {
              //   client: "oV922HIVCL",
              //   channels: ["8PtYIoCH"],
              // };

              // if (data.request.phone)
              // http://localhost:3000/channels?client=oV922HIVCL&channels=[8PtYIoCH]&state=5a424204-ed42-4e69-8241-dc0b9aec8684
              // http://localhost:3000/channels?client=oV922HIVCL&channels=[8PtYIoCH]&state=23b8d988-0e88-4b98-850c-cbafa26b8759

              if (funapiiResp.data.data[0].setup_info.phone_number.includes(data.request.phone)) {
                let dataUtil = { ...data };
                dataUtil.request.phone = funapiiResp.data.data[0].setup_info.phone_number;
                dataUtil.request["waba_name"] = funapiiResp?.data?.data[0]?.setup_info?.phone_name;
                await updateDB(dataUtil);
                installChannel(dataUtil, callbackObject, wisecrackChannel);
              } else {
                if (window.confirm("The number you selected in 360Dialog is different from the one provided in the form. Would yo like to keep this number and update the one in the form?")) {
                  let dataUtil = { ...data };
                  dataUtil.request.phone = funapiiResp.data.data[0].setup_info.phone_number;
                  dataUtil.request["waba_name"] = funapiiResp?.data?.data[0]?.setup_info?.phone_name;

                  await updateDB(dataUtil);
                  installChannel(data, callbackObject, wisecrackChannel);
                } else {
                  navigate("/channels");
                }
              }
            }
          } else {
            console.log("CHANNEL NOT FOUND");
            //   console.log("No se encontró el documento!");
            // }
          }
        })
        .catch((error) => {
          console.log("Error al obtener el documento:", error);
        });
    }
  };

  function checkChannelInstalled(channelID: string, cust: any) {
    console.log("checkChannelInstalled", channelID, cust);

    const isInstalled = cust.integrations.some((int: any) => {
      return int?.config?.credentials?.channel_id == channelID;
    });
    console.log("🚀 ~ isInstalled:", isInstalled);
    return isInstalled;
  }

  function handleRegisterNumber(data: any, customerId: any) {
    const payload = {
      id: data.sender,
      type: "PSTN",
      phone_number: data.sender,
      owner_id: customerId,
      status: "BLOCKED",
      carrier: "NETELIP",
      platform: "JAMBONZ",
      platform_id: "jambonz",
    };
    API.post("/numbers", payload)
      .then((res) => {
        console.log("AXIOS NUMBERS", res.data);
      })
      .catch((err) => {
        console.log(err?.response?.data?.detail);
      });
  }
  async function handleWebHook(data: any) {
    // let config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // };

    // const token = await auth.currentUser?.getIdToken();
    // if (token) {
    //   let configUtil: any = { ...config.headers };
    //   configUtil["Authorization"] = `Bearer ${token}`;
    //   config.headers = { ...configUtil };
    // } else {
    //   console.log("auth token no generado");
    // }

    FUNAPI.post("webhook", { api_key: data.api_key })
      .then((res) => {
        console.log("AXIOS WEBHOOK", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleNewConnection(config: any, callbackObject: any, channel: string, customerIdUtil: string | null, newIdUtil: string, update = false, integration: any) {
    let integrationUtil: any = {};

    if (update) {
      integrationUtil = { ...integration };
    } else {
      integrationUtil = { ...defaultInteg };
      integrationUtil.id = newIdUtil;
      integrationUtil.name = `360D-${config.sender}`;
    }
    // integrationUtil.config.nlg_defaults = nlgDefaults !== "" ? JSON.parse(nlgDefaults) : {};
    // integrationUtil.config.reserved_tags = reservedTags ? JSON.parse(reservedTags) : [];

    integrationUtil.config.sender_phone = config.sender;
    integrationUtil.config.credentials = {
      endpoint_base_path: "https://waba-v2.360dialog.io",
      api_key: config.api_key,
      waba_account_id: config.waba_account_id,
      client_id: callbackObject.client,
      channel_id: channel,
    };

    if (update) {
      API.put(`/customers/${customerIdUtil}/integrations/${integrationUtil.id}`, integrationUtil)
        .then((res) => {
          // console.log(res.data);
          addToast(`Integration updated! >> ${integrationUtil.name}`, {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 3000,
          });
          // if (lastChannel) {
          //   navigate(`/integrations/whatsapp_360d/?id=${newIdUtil}`);
          //   // setForceRender(forceRender + 1);
          // }
        })
        .catch((err) => {
          addToast("Error", {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 3000,
          });
          console.log(err);
        });
    } else {
      API.post(`/customers/${customerIdUtil}/integrations`, integrationUtil)
        .then((res) => {
          // console.log(res.data);
          addToast(`Integration added! >> ${integrationUtil.name}`, {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 3000,
          });
        })
        .catch((err) => {
          addToast("Error", {
            appearance: "error",
            autoDismiss: true,
            autoDismissTimeout: 3000,
          });
          console.log(err);
        });
    }
  }

  function handleFinish(newRequest: any) {
    console.log("NEW REQUEST", newRequest);

    const configUtil: any = JSON.parse(JSON.stringify(channelsConfigs));
    configUtil.push(newRequest);
    setChannelsConfigs(configUtil);
    setLoading(false);
    setWhatsModalOpen(false);
  }
  function handleClick(request_id: string) {
    navigate(`/channels/${request_id}`);
  }
  // function handleDeleteVenue(venueParam: string, index: number) {
  //   if (window.confirm(`Are you sure you want to remove ${venueParam}?`)) {
  //     const configUtil = JSON.parse(JSON.stringify(channelsConfigs));
  //     configUtil[index].venues = configUtil[index].venues.filter((venue: string) => venue != venueParam);
  //     console.log("updated config", configUtil);
  //     updateDB(configUtil[index]);
  //     setChannelsConfigs(configUtil);
  //   }
  // }

  // function addVenue(value: string, index: number) {
  //   console.log(value);
  //   const configUtil = JSON.parse(JSON.stringify(channelsConfigs));

  //   configUtil[index].venues.push(value);
  //   setChannelsConfigs(configUtil);
  //   updateDB(configUtil[index]);

  //   setNewVenue(null);
  // }

  function setChannelRequestOffline(data: any) {
    let dataUtil = { ...data };
    dataUtil.request.status = "offline";
    updateDB(dataUtil);
  }

  async function updateDB(data: any) {
    const docRef = doc(dbWisecrack, "channels", data.id);
    // const docRef = doc(dbWisecrack, "whatsapp_config", data.id);
    await setDoc(docRef, data);
    return true;
  }
  function getColor(status: string) {
    switch (status) {
      case "online":
        return "green";
      case "in_review":
        return "blue";
      case "offline":
        return "red";
      case "draft":
        return "#bfbfbf";
      case "ready for onboarding":
        return "orange";
      default:
        return "orange";
    }
  }

  useEffect(() => {
    const wisecrackChannel = searchParams.get("state");
    if (wisecrackChannel) {
      // console.log("wisecrackChannel", wisecrackChannel);
    } else if (customer && customer.id !== "defaultCustomer") {
      // console.log("checking wisecrack");
      const q = query(collection(dbWisecrack, "channels"), where("owner_id", "==", customer.id), where("channel_type", "==", "WHATSAPP"));
      // const q = query(collection(dbWisecrack, "whatsapp_config"), where("owner_id", "==", customer.id));
      getDocs(q).then(async (querySnapshot) => {
        const dataWisecrackArray = querySnapshot.docs.map((doc) => doc.data());
        // console.log("dataWisecrackArray", dataWisecrackArray);

        const onlineChannels = dataWisecrackArray.filter((channel: any) => channel.request.status === "online");
        console.log("onlineChannels", onlineChannels);
        for (let i = 0; i < onlineChannels.length; i++) {
          const dbCustomer: any = await API.get("/customers/" + customer.id);
          const customerIntegration: any = dbCustomer?.data?.integrations.find((int: any) => int.id === onlineChannels[i].integration_id);
          let apiKey = null;
          if (customerIntegration) {
            apiKey = customerIntegration.config?.credentials?.api_key;
          }

          if (apiKey) {
            try {
              const res = await fetch("https://proxy.dev.bookline.io/360d_waba/v1/configs/phone_number", {
                method: "GET",
                credentials: "include",
                headers: {
                  "D360-API-KEY": apiKey,
                },
              });

              if (res.status >= 200 && res.status < 300) {
                const data = await res.json();
                console.log("Phone:", data);
              } else {
                setChannelRequestOffline(onlineChannels[i]);
                console.log("ERROR", res);
              }
            } catch (e) {
              console.log("ERROR", e);
            }
          } else {
            setChannelRequestOffline(onlineChannels[i]);

            console.log("NO API KEY FOUND IN CUSTOMER INTEGRATION");
          }
        }
        setChannelsConfigs(dataWisecrackArray);
        setLoading(false);
      });
    }
  }, [customer]);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spinner className="spinner" size="md" color="primary"/>
      </div>
    );
  }
  return (
    <div id="page-wrapper" className="container" >
      <StyledWelcomeArea>
        <StyledWelcomeLeft>
          <Breadcrumb prev={[{ text: t("general"), link: "/" }]} title={t("channel_setup")} wcText={t("channel_setup")} />
          <ConnectButton style={{ display: "none" }} label="Conectar con 360Dialog" partnerId="JlKl7HPA" callback={handleCallback} className="connectButton" />
          {/* <Segmented
            style={{ marginTop: "30px", marginBottom: "30px" }}
            options={["VOICE", "WHATSAPP"]}
            onChange={(value) => {
              console.log(value);
            }}
            value={"WHATSAPP"}
          /> */}
        </StyledWelcomeLeft>
        <StyledWelcomeRight>
          {/* <Button type="primary" onClick={() => setWhatsModalOpen(true)}>
            {t("add_new")}
          </Button> */}
        </StyledWelcomeRight>
      </StyledWelcomeArea>

      {channelsConfigs?.length === 0 && !loading && (
        <div className="emptyStateWrapper">
          {/* Sección de contacto */}
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={16} lg={16}>
              <div className="contact-wrapper">
                <h2 className="text-style" style={{color: 'white', fontSize: '24px', fontWeight: 'bolder'}}>{t("channels_contact_title")}</h2>
                <p style={{color: 'white'}}>{t("channels_contact_desc")}</p>
                <div className="contact-link">
                  <a href="mailto:digame@bookline.io" className="cta-link">
                    Contact us &gt;
                  </a>
                </div>
              </div>
            </Col>
            {/* Sección de demo */}
            <Col xs={24} sm={12} lg={8}>
              <div className="try-demo-wrapper">
                <h2 style={{fontSize: '24px', fontWeight: 'bolder'}} className="text-style">{t("channels_try_demo_title")}</h2>
                <p style={{fontSize: '16px'}}>{t("channels_try_demo_desc")}</p>
                <a href="https://bookline.io/" className="cta-link" target="_blank" rel="noopener noreferrer">
                  Try demo &gt;
                </a>
              </div>
            </Col>
          </Row>
          {/* Sección de WhatsApp setup */}
          <Col xs={24} sm={24} lg={24}>
            <div className="whatsapp-wrapper">
                <img style={{padding: '8px', maxHeight: '150px'}} src={whatsImage} alt="WhatsApp chat" />
              <div className="whatsapp-text">
                <h2 style={{fontWeight: 'bold', marginBottom: '20px'}}>{t("channels_whats_title")}</h2>
                <p style={{fontSize: '20px'}}>{t("channels_whats_desc")}</p>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                  <StyledButton style={{ textTransform: 'none', fontSize: '18px' }} onClick={() => setWhatsModalOpen(true)}>Start setup</StyledButton>
                </div>
              </div>
            </div>
          </Col>
        </div>
      )}

      {channelsConfigs?.length > 0 && (
        <div className="whatsappListContainer">
          {channelsConfigs.map((channel_config: any, index: number) => (
            <div onClick={() => handleClick(channel_config.id)} key={index} className="whatsappRequestItem">
              <div>
                <div className="listTitle">{channel_config?.request?.waba_name ?? `WhatsApp ${index + 1}`}</div>
                <div className="listSubtitle">{channel_config.request.phone}</div>
              </div>
              <div className="tagContainer">
                {channel_config.request.status === "online" && <div className="statusTag online-tag">Online</div>}
                {channel_config.request.status === "in_review" && <div className="statusTag in-review-tag">In review</div>}
                {channel_config.request.status === "offline" && <div className="statusTag offline-tag">Offline</div>}
                {channel_config.request.status === "draft" && <div className="statusTag draft-tag">Draft</div>}
                {channel_config.request.status === "ready_for_onboarding" && <div className="statusTag ready-for-onboarding-tag">Ready for Onboarding</div>}

                <svg style={{ margin: "0px 20px" }} width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.02461 9.09862L0.824642 16.4978C0.765364 16.5575 0.718499 16.6282 0.686761 16.7061C0.655023 16.784 0.639039 16.8673 0.639737 16.9514C0.640436 17.0355 0.657802 17.1186 0.690829 17.1959C0.723858 17.2732 0.771892 17.3433 0.832152 17.4019C0.892413 17.4605 0.963704 17.5066 1.0419 17.5375C1.1201 17.5684 1.20364 17.5835 1.28771 17.5819C1.37178 17.5803 1.45469 17.5621 1.53165 17.5282C1.60861 17.4943 1.6781 17.4456 1.73608 17.3847L9.36676 9.54204C9.48225 9.42333 9.54688 9.26424 9.54688 9.09862C9.54688 8.93299 9.48225 8.7739 9.36676 8.65519L1.73608 0.812551C1.6781 0.751665 1.60861 0.702883 1.53165 0.669027C1.45469 0.635172 1.37178 0.616917 1.28771 0.615314C1.20364 0.613714 1.1201 0.628801 1.0419 0.659702C0.963704 0.690605 0.892413 0.736706 0.832152 0.795341C0.771892 0.853977 0.723858 0.923981 0.690829 1.0013C0.657802 1.07862 0.640436 1.16173 0.639737 1.24581C0.639039 1.32988 0.655023 1.41327 0.686761 1.49113C0.718499 1.56898 0.765364 1.63978 0.824642 1.69941L8.02461 9.09862Z"
                    fill="#8692A3"
                  />
                </svg>
              </div>
            </div>
          ))}
        </div>
      )}

      <Modal
        open={whatsModalOpen}
        onCancel={() => {
          setWhatsModalOpen(false);
        }}
        width={1000}
        footer={null}
      >
        <WhatsAppWizardContainer handleFinish={(e: any) => handleFinish(e)} />
      </Modal>
    </div>
  );
};
export default ChannelsContainer;
