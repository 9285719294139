/* eslint-disable */
import React from "react";
import { Container } from "@doar/components";
import { SpaceProps } from "@doar/shared/styled";
import { StyledContentBody } from "./style";
import "./styles.css";

interface IProps extends SpaceProps {
  container?: boolean;
}

const ContentBody: React.FC<IProps> = ({ children, container, ...rest }) => {
  return (
    <StyledContentBody className="content-body" {...rest}>
      {container && (
        <Container className="container customContainer" px="0" mb="25px">
          {children}
        </Container>
      )}
      {!container && <>{children}</>}
    </StyledContentBody>
  );
};

ContentBody.defaultProps = {
  container: true,
};

export default ContentBody;
