/* eslint-disable */
import styled, { css, themeGet } from "@doar/shared/styled";

export const StyledWrap = styled.div`
    padding-top: 20px;
    border-bottom: 1px solid ${themeGet("colors.border")};
    flex: 1 1 auto;
    margin-bottom: 10px;

    .paginationControls {
        margin-left: 10px;
        margin-top: 10px;
        position: fixed;
        display: flex;
        align-items: center;
        bottom: 10px;

        button {
            margin: 0 10px !important;
        }
    }
    .selectLimit {
        margin: 0 10px !important;
        width: auto !important;
    }
    .header {
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 5px;
    }
`;

export const StyledList = styled.div`
    .item {
        margin-top: 5px;
        margin-right: 10px;
    }
    .radioItems {
        display: flex;

        .item {
            margin-left: 15px;
        }
    }
`;


interface IProps {
    $active: boolean;
}

