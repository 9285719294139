/* eslint-disable */
import React, { useState } from "react";
import { Row, Col } from "@doar/components";
import ContentBody from "../layouts/layout-02/content-body";
import WelcomeArea from "../containers/dashboard-four/welcome-area";
import LeftRowOne from "../containers/dashboard-four/left-row-one";
import RightRowOne from "../containers/dashboard-four/right-row-one";
import SEO from "../components/seo";
import useSidebarMargin from "src/layouts/useSidebarMargin";
import ContentHeader from "src/layouts/layout-02/content-header";

const DashboardFour: React.FC = () => {
  const [botId, setBotId] = useState("");
  function handleChange(e: any) {
    setBotId(e);
  }

  const marginLeft = useSidebarMargin();

  return (
    <div style={{ marginLeft }}>
      <ContentHeader></ContentHeader>
        <SEO />
          <ContentBody>
            <WelcomeArea />
            <Row gutters={10}>
              <Col lg={8}>
                <Row gutters={10}>
                  <LeftRowOne change={handleChange} />
                  {/* <LeftRowTwo />
                                  <LeftRowThree /> */}
                </Row>
              </Col>
              <Col lg={4} mt={["10px", null, null, 0]}>
                <Row gutters={10}>
                  <RightRowOne prefill={botId} />
        
                  {/* <RightRowTwo />
                                  <RightRowhree />
                                  <RightRowFour /> */}
                </Row>
              </Col>
            </Row>
          </ContentBody>
      </div>
  );
};

export default DashboardFour;
