/* eslint-disable */
import { ISize } from "@doar/shared/types";
import { useContext, useState, useEffect } from "react";
import { ShowSidebarContext } from "src/config/userContext";
import { useWindowSize } from "src/hooks";

const useSidebarMargin = () => {
  const { showSidebar, showSidebarFixed } = useContext(ShowSidebarContext);
  const size: ISize = useWindowSize();  

  const [marginLeft, setMarginLeft] = useState("0px");

  useEffect(() => {
    const width = size?.width || 1024; 

    if (width > 992) {
      setMarginLeft(showSidebar || !showSidebarFixed ? "240px" : "60px");
    } else {
      setMarginLeft("0px"); 
    }
  }, [size.width, showSidebar, showSidebarFixed]);

  return marginLeft;
};

export default useSidebarMargin;
