/* eslint-disable */
/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable prefer-template */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import "./style.css";
import { FC, useContext, useEffect, useState } from "react";
import { Plus } from "react-feather";
import { useNavigate } from "react-router-dom";
import { CustomerContext } from "src/config/userContext";

import { Spinner, Table } from "@doar/components";
import colzentLogo from "@doar/shared/images/integrations/colzentLogo.png";
import coverManagerLogo from "@doar/shared/images/integrations/coverManagerLogo.svg";
import dentalinkLogo from "@doar/shared/images/integrations/dentalinkLogo.svg";
import golfManagerLogo from "@doar/shared/images/integrations/golfManagerLogo.png";
import googleMapsLogo from "@doar/shared/images/integrations/googleMapsLogo.svg";
import netelipLogo from "@doar/shared/images/integrations/netelipLogo.svg";
import restooLogo from "@doar/shared/images/integrations/restooLogo.svg";
import sendgridLogo from "@doar/shared/images/integrations/sendgridLogo.svg";
import twilioLogo from "@doar/shared/images/integrations/twilioLogo.svg";
import whatsapp360Logo from "@doar/shared/images/integrations/whatsapp360Logo.png";
import nisLogo from "@doar/shared/images/integrations/nisLogoEdited.png";
import API from "@doar/shared/methods/api";

import Breadcrumb from "../../components/breadcrumb";
import { StyledButton, StyledWelcomeArea, StyledWelcomeLeft, StyledWelcomeRight } from "../dashboard-four/welcome-area/style";
import { useToasts } from "react-toast-notifications";

const IntegrationsContainer: FC = () => {
  const { customer, customerUpdate } = useContext(CustomerContext);
  const { addToast } = useToasts();
  // const [showModal, setShowModal] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [integrationList, setIntegrationList] = useState<any[]>([]);
  const navigate = useNavigate();

  const handleMarket = () => {
    navigate(`/marketplace`);
  };
  const getImage = (integration: any) => {
    switch (integration.config.integration_type) {
      case "DENTALINK":
        return dentalinkLogo;
      case "GOLFMANAGER":
        return golfManagerLogo;
      case "RESTOO":
        return restooLogo;
      case "COVERMANAGER":
        return coverManagerLogo;
      case "EMAIL_SENDGRID":
        return sendgridLogo;
      case "SMS_TWILIO":
        return twilioLogo;
      case "SMS_NETELIP":
        return netelipLogo;
      case "WHATSAPP_360D":
        return whatsapp360Logo;
      case "GOOGLE_MAPS":
        return googleMapsLogo;
      case "COLZENT":
        return colzentLogo;
      case "NIS":
        return nisLogo;
      default:
        return "https://media-exp1.licdn.com/dms/image/C560BAQGS7sdpNYWnEA/company-logo_200_200/0/1612902438159?e=2147483647&v=beta&t=w1NqDOjYxZIEsMXd2AQQF0IXHmoxmEuP3rYsKG_Vfdk";
    }
  };

  const handleEdit = (integration?: any) => {
    if (integration) {
      navigate(`/integrations/${integration.config.integration_type.toLowerCase()}/${integration.id}`);
    }
  };
  // const handleClose = () => {
  //     setShowModal((prev) => !prev);
  // };

  useEffect(() => {
    setSpinner(true);
    if (customer?.id && customer?.id !== "defaultCustomer") {
      API.get(`/customers/${customer?.id}/integrations`)
        .then((res) => {
          setSpinner(false);

          let customerUtil = { ...customer };
          customerUtil.integrations = res.data.data;
          customerUpdate(customerUtil);

          let listSorted = res.data.data.sort(function (a: any, b: any) {
            if (a.config.integration_type > b.config.integration_type) {
              return 1;
            }
            if (a.config.integration_type < b.config.integration_type) {
              return -1;
            }
            return 0;
          });

          setIntegrationList(listSorted);
        })
        .catch((err) => console.log(err));
    } else {
      setSpinner(false);
    }
  }, [customer?.id]);

  useEffect(() => {
    if (!customer?.id) {
      addToast("Por favor selecciona un customer", {
        appearance: "warning",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  }, []);

  return (
    <div id="page-wrapper" className="container">
      <StyledWelcomeArea>
        <StyledWelcomeLeft>
          <Breadcrumb
            prev={[
              { text: "General", link: "/" },
              { text: "integrations", link: "/integrations" },
            ]}
            title="Installed"
            // wcText="Integrations"
          />
        </StyledWelcomeLeft>
        <StyledWelcomeRight>
          {/* {!showNew && !editMode && ( */}
          <StyledButton size="sm" ml="10px" hasIcon onClick={handleMarket}>
            <Plus />
            Add New Integration
          </StyledButton>
        </StyledWelcomeRight>
      </StyledWelcomeArea>
      {spinner && (
        <div className="spinnerContainer">
          <Spinner className="spinner" size="xl" />
        </div>
      )}
      {!spinner && (
        <Table mb="50px" hover>
          <thead>
            <tr>
              <th scope="col">Empresa</th>
              <th scope="col">Nombre</th>
              <th scope="col">ID</th>
              <th scope="col">Extra Data</th>
            </tr>
          </thead>
          <tbody>
            {integrationList.map((integration: any) => (
              <tr className="1" onClick={() => handleEdit(integration)} key={integration.id}>
                <td className="rowElement">
                  <img src={getImage(integration)} alt="integration logo" height="50px" width="120px" />
                </td>
                <td className="rowElement">{integration.name}</td>
                <td className="rowElement">{integration.id}</td>
                <td className="rowElement">
                  {integration.config.sender_phone ? "phone: " + integration.config.sender_phone.e164 : ""}
                  {integration.config.slug ? "slug: " + integration.config.slug : ""}
                  {integration.config.user_name ? "username: " + integration.config.user_name : ""}
                  {integration.config.station ? "station: " + integration.config.station : ""}
                  {integration.config.credentials?.account_sid ? "acc sid: " + integration.config.credentials.account_sid : ""}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};
export default IntegrationsContainer;
