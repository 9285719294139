/* eslint-disable */
/* eslint-disable import/no-unresolved */
import { FC } from "react";
import IntegrationsContainer from "src/containers/integrations";
import SEO from "../../components/seo";
import ContentBody from "../../layouts/layout-02/content-body";
import useSidebarMargin from "src/layouts/useSidebarMargin";
import ContentHeader from "src/layouts/layout-02/content-header";

const Integrations: FC = () => {
  const marginLeft = useSidebarMargin();

  return (
    <div style={{ marginLeft }}>
      <ContentHeader></ContentHeader>
        <SEO />
        <ContentBody container={false}>
          <IntegrationsContainer />
        </ContentBody>
      </div>
  );
};

export default Integrations;
