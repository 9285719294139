/* eslint-disable */
import { FC, useEffect } from "react";
import ScrollBar from "../../../../components/scrollbar";
import ChatCallGroup from "../../../../components/apps/calls/chat-group";
import DirectTitle from "../../../../components/apps/calls/direct-title";
import { useAppSelector, useAppDispatch } from "../../../../redux/hooks";
import { toggleSidebar } from "../../../../redux/slices/chat-ui";

import { StyledMain, StyledHeader, StyledBody, StyledBodyInner } from "./style";
import CallAudio from "src/components/apps/chat/call-audio";

interface IProps {
  layout?: 1 | 2 | 3;
}

const Main: FC<IProps> = ({ layout }) => {
  const dispatch = useAppDispatch();
  const { chatType, rightSidebar } = useAppSelector((state) => state.callsUI);
  const { sidebar } = useAppSelector((state) => state.ui);
  const { call } = useAppSelector((state) => state.callsUI);

  useEffect(() => {
    if (window.matchMedia("(max-width: 991px)").matches) {
      dispatch(toggleSidebar());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledMain className="content" $layout={layout} $sidebar={sidebar}>
      <StyledHeader>{chatType === "direct" && <DirectTitle />}</StyledHeader>
      <StyledBody $showSidebar={rightSidebar}>
        <CallAudio />
        <ScrollBar bottom={call?.payload?.sid ? 60 : 0}>
          <StyledBodyInner>
            <ChatCallGroup />
          </StyledBodyInner>
        </ScrollBar>
      </StyledBody>
    </StyledMain>
  );
};

Main.defaultProps = {
  layout: 1,
};

export default Main;
